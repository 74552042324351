import {
  CustomCode,
  CustomCostGroupDefinition,
  CustomCostGroupDefinitionHierarchy,
} from 'types/CostGroup';
import { useCurrentCompany } from './useCurrentCompany';
import { useQuery } from '@tanstack/react-query';
import { Resources } from 'api/Resources';
import { queryKeys } from 'utils/reactQuery';
import { isEmpty, keyBy, values } from 'lodash-es';
import { ApiService } from 'api/ApiService';
import { useMemo } from 'react';

// Function to build the hierarchical structure
function buildHierarchy(
  data: CustomCostGroupDefinition[],
): CustomCostGroupDefinitionHierarchy[] {
  if (isEmpty(data)) {
    return [];
  }

  const idMap: Record<number, CustomCostGroupDefinitionHierarchy> = {}; // Map to store all nodes by their IDs

  // Populate idMap with all nodes
  data.forEach((node) => {
    idMap[node.id] = {
      ...node,
      allHierarchyCodesById: {},
      allHierarchyCodesByDivCode: {},
      allHierarchyDefinitionsIds: [],
      custom_codes:
        node.custom_codes?.map((customCode) => ({
          ...customCode,
          children: [],
        })) || [],
      getMostSpecificCostGroup: () => null,
    };
  });

  // Build the hierarchy recursively
  const buildChildren = (
    parentNode: CustomCostGroupDefinitionHierarchy,
    childByParentId: Record<number, number>,
  ) => {
    const childNode = values(idMap).find(
      (node) => parentNode.child_definition === node.id,
    );

    if (!childNode) return;
    childByParentId[parentNode.id] = childNode.id;

    parentNode.custom_codes.forEach((parentCustomCode) => {
      const matchingChildren = childNode.custom_codes.filter(
        (childCustomCode) => childCustomCode.parent_id === parentCustomCode.id,
      );

      matchingChildren.forEach((childCustomCode) => {
        parentCustomCode.children.push({
          ...childCustomCode,
          children: [],
        });
      });

      parentCustomCode.children.forEach((childCustomCode) => {
        buildChildren({ ...childNode, custom_codes: [childCustomCode] }, childByParentId);
      });
    });
  };

  // Determine root nodes
  const rootNodes = values(idMap).filter(
    (node) => !values(idMap).some((otherNode) => otherNode.child_definition === node.id),
  );

  const childByParentId: Record<number, number> = {};
  // Construct the hierarchy
  rootNodes.forEach((node) => buildChildren(node, childByParentId));
  rootNodes.forEach((node) => {
    node.allHierarchyDefinitionsIds = [node.id];
    node.allHierarchyCodesById = node.custom_codes.reduce(
      (acc, customCode) => {
        acc[customCode.id] = customCode;
        return acc;
      },
      {} as Record<number, CustomCode>,
    );
    node.allHierarchyCodesByDivCode = node.custom_codes.reduce(
      (acc, customCode) => {
        acc[customCode.div_code] = customCode;
        return acc;
      },
      {} as Record<string, CustomCode>,
    );
    let currentId = node.id;
    while (childByParentId[currentId]) {
      node.allHierarchyDefinitionsIds.push(childByParentId[currentId]);
      node.allHierarchyCodesById = idMap[childByParentId[currentId]].custom_codes.reduce(
        (acc, customCode) => {
          acc[customCode.id] = customCode;
          return acc;
        },
        node.allHierarchyCodesById,
      );
      node.allHierarchyCodesByDivCode = idMap[
        childByParentId[currentId]
      ].custom_codes.reduce((acc, customCode) => {
        acc[customCode.div_code] = customCode;
        return acc;
      }, node.allHierarchyCodesByDivCode);
      currentId = childByParentId[currentId];
    }
    node.getMostSpecificCostGroup = (costGroups) => {
      if (!costGroups) return null;
      // Reverse iteration to get the most specific cost group
      for (let i = node.allHierarchyDefinitionsIds.length - 1; i >= 0; i--) {
        const definitionId = node.allHierarchyDefinitionsIds[i];
        const costGroup = costGroups.find((cg) => cg.definition === definitionId);
        if (costGroup) {
          return costGroup;
        }
      }
      return null;
    };
  });

  return rootNodes;
}
export const useCustomCostGroupDefinitions = <T extends CustomCostGroupDefinition>({
  projectId = null,
  milestoneId = null,
  includeDetails = false,
  excludeEstimatesOnly = false,
  includeChildren = false,
}: {
  projectId?: number | null | undefined;
  milestoneId?: number | null | undefined;
  excludeEstimatesOnly?: boolean;
  includeDetails?: boolean;
  includeChildren?: boolean;
} = {}) => {
  const { currentCompany } = useCurrentCompany();

  const customCostGroupDefinitionsQuery = useQuery({
    queryKey: queryKeys
      .company(currentCompany?.id)
      .customCostGroupDefinitions()
      .details(includeDetails, projectId, milestoneId, includeChildren),
    queryFn: ({ signal }) => {
      const endpoint = Resources.CUSTOM_CODE_DEFINITIONS as string;
      let params = {};
      if (milestoneId) {
        params = { milestone_id: milestoneId };
      }
      if (projectId) {
        params = { ...params, project_id: projectId };
      }
      if (includeDetails) {
        params = { ...params, include_details: true };
      }
      if (includeChildren) {
        params = { ...params, include_children: true };
      }
      return ApiService.get(endpoint, { signal, params }).then((res) => res.data as T[]);
    },
    enabled: !!currentCompany,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const allDefinitions = useMemo(() => {
    const r = excludeEstimatesOnly
      ? customCostGroupDefinitionsQuery.data?.filter(
          (definition) => !definition.is_estimates_only,
        )
      : customCostGroupDefinitionsQuery.data;

    return r || [];
  }, [customCostGroupDefinitionsQuery.data, excludeEstimatesOnly]);

  const { customCostGroupDefinitions, customCostGroupDefinitionsById } = useMemo(() => {
    const customCostGroupDefinitions = allDefinitions.filter(
      (definition) => !definition.is_standard,
    );
    const customCostGroupDefinitionsById = keyBy(customCostGroupDefinitions, 'id');
    return { customCostGroupDefinitions, customCostGroupDefinitionsById };
  }, [allDefinitions]);

  const { standardCostGroupDefinitions, standardCostGroupDefinitionsById } =
    useMemo(() => {
      const standardCostGroupDefinitions = allDefinitions.filter(
        (definition) => definition.is_standard,
      );
      const standardCostGroupDefinitionsById = keyBy(standardCostGroupDefinitions, 'id');
      return { standardCostGroupDefinitions, standardCostGroupDefinitionsById };
    }, [allDefinitions]);

  const { rootDefinitions, rootDefinitionsBydId } = useMemo(() => {
    const rootDefinitions = allDefinitions.every((definition) =>
      isEmpty(definition.custom_codes),
    )
      ? []
      : buildHierarchy(allDefinitions as CustomCostGroupDefinition[]);

    const rootDefinitionsBydId = keyBy(rootDefinitions, 'id');

    return { rootDefinitions, rootDefinitionsBydId };
  }, [allDefinitions]);

  return {
    customCostGroupDefinitionsQuery: {
      ...customCostGroupDefinitionsQuery,
      costGroupDefinitionsById: useMemo(
        () => keyBy(allDefinitions, 'id') as Record<string | number, T>,
        [allDefinitions],
      ),
      costGroupDefinitions: allDefinitions,
      standardCostGroupDefinitionsById,
      standardCostGroupDefinitions,
      customCostGroupDefinitions,
      customCostGroupDefinitionsById,
      rootDefinitions,
      rootDefinitionsBydId,
      getRootDefinition: (definitionId: number) =>
        rootDefinitions.find((rootDefinition) => {
          if (rootDefinition.allHierarchyDefinitionsIds.includes(definitionId)) {
            return rootDefinition;
          }
          return null;
        }),
    },
  };
};
