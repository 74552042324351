import {
  GridCellEditStopReasons,
  GridCellParams,
  GridRenderEditCellParams,
  MuiEvent,
} from '@mui/x-data-grid';
import { BaseSyntheticEvent, MutableRefObject } from 'react';
import {
  gridExpandedSortedRowIdsSelector,
  GridValidRowModel,
  GridColDef,
  GridColumnOrderChangeParams,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { pick } from 'lodash-es';

type UseTableHelpersProps = {
  gridApiRef: MutableRefObject<GridApiPremium | null>;
};
export function useTableHelpers({ gridApiRef }: UseTableHelpersProps) {
  const handleCellKeyDown = (
    params: GridCellParams,
    event: MuiEvent<React.KeyboardEvent>,
  ) => {
    if (
      event.key !== 'Tab' &&
      (event.key !== 'ArrowDown' || (event.key === 'ArrowDown' && !event.altKey))
    ) {
      return;
    }

    const gridApi = gridApiRef.current;
    if (!gridApi) return;

    // alt + ⬇
    if (event.key === 'ArrowDown') {
      try {
        gridApi.startCellEditMode(pick(params, ['id', 'field']));
      } catch (e) {}
      return;
    }

    const rowIds = gridExpandedSortedRowIdsSelector(gridApi.state, gridApi.instanceId);
    const visibleColumns = gridApi.getVisibleColumns();

    const nextCell = {
      rowIndex: rowIds.findIndex((id) => id === params.id),
      columnIndex: gridApi.getColumnIndex(params.field),
    };

    if (
      nextCell.columnIndex === visibleColumns.length - 1 &&
      nextCell.rowIndex === rowIds.length - 1 &&
      !event.shiftKey
    ) {
      // Do nothing if we are at the last cell of the last row
      return;
    }

    if (nextCell.columnIndex === 0 && nextCell.rowIndex === 0 && event.shiftKey) {
      // Do nothing if we are at the first cell of first row
      return;
    }

    event.preventDefault();
    event.defaultMuiPrevented = true;

    if (!event.shiftKey) {
      if (nextCell.columnIndex < visibleColumns.length - 1) {
        nextCell.columnIndex += 1;
      } else {
        nextCell.rowIndex += 1;
        nextCell.columnIndex = 0;
      }
    } else if (nextCell.columnIndex > 0) {
      nextCell.columnIndex -= 1;
    } else {
      nextCell.rowIndex -= 1;
      nextCell.columnIndex = visibleColumns.length - 1;
    }
    gridApi.scrollToIndexes(nextCell);

    const field = visibleColumns[nextCell.columnIndex].field;
    const id = rowIds[nextCell.rowIndex];
    gridApi.setCellFocus(id, field);
  };

  const handleClipboardPasteStart = ({
    data,
    onAddRow,
  }: {
    data: string[][];
    onAddRow: () => GridValidRowModel[];
  }) => {
    const gridApi = gridApiRef.current;
    if (!gridApi) return;
    const pastedRowsLength = data.length;
    const totalRowsLength = gridApi.state.rows.dataRowIds.length;
    const currentRowIndex = gridApi.state.rows.dataRowIds.findIndex(
      (id) => id === gridApi.state.focus.cell?.id,
    );
    if (currentRowIndex < 0) return;
    if (totalRowsLength > currentRowIndex + pastedRowsLength) return;
    const rowsToAdd = currentRowIndex + pastedRowsLength - totalRowsLength;
    if (rowsToAdd <= 0) return;
    let rows;
    for (let i = 0; i < rowsToAdd; i++) {
      rows = onAddRow();
    }
    if (rows) gridApi.setRows(rows);
  };

  const handleStopEditing = async <T>(
    event: BaseSyntheticEvent,
    params: GridRenderEditCellParams,
    newValue?: T,
  ) => {
    const { id, field } = params;
    const gridApi = gridApiRef.current;
    if (!gridApi) return;
    if (newValue) {
      await gridApi.setEditCellValue({
        id,
        field,
        value: newValue,
      });
    }
    try {
      gridApi.stopCellEditMode({ id, field });
      // Hack to trigger onCellEditStop
      const newParams = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(params as unknown as GridRenderEditCellParams<any, any, any>),
        reason: GridCellEditStopReasons.cellFocusOut,
      };
      gridApi.publishEvent('cellEditStop', newParams, event);
    } catch (e) {}
  };

  return {
    handleCellKeyDown,
    handleClipboardPasteStart,
    handleStopEditing,
  };
}

export function useTableColumnFilters({
  disableToggableColumns = [],
}: {
  disableToggableColumns?: string[];
} = {}) {
  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter(
        (column) =>
          !column.field.startsWith('__') &&
          !disableToggableColumns.includes(column.field),
      )
      .map((column) => column.field);
  };

  return {
    getTogglableColumns,
  };
}

export function changeColumnOrder(
  columns: string[],
  columnChange: GridColumnOrderChangeParams,
) {
  const currentIndex = columns.findIndex((field) => field === columnChange.column.field);
  const value = columns[currentIndex];
  const updatedOrderedFields = [...columns];

  if (currentIndex !== -1) {
    updatedOrderedFields.splice(currentIndex, 1);
  }
  updatedOrderedFields.splice(columnChange.targetIndex, 0, value);
  return updatedOrderedFields;
}
