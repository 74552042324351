import { createContext } from 'react';
import { KeycloakState } from './types';

export const initialContextValue: KeycloakState = {
  keycloak: undefined,
  isAuthenticated: false,
  isAuthLoading: true,
  errorMessage: undefined,
};

export const KeycloakContext = createContext<KeycloakState>(initialContextValue);
