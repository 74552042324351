import { useContext } from 'react';
import { KeycloakContext } from '../components/KeycloakContext';
import { isByPassAuth } from 'utils/environment';
import { getCookie } from './sessionHelpers';

export function useKeycloak() {
  const context = useContext(KeycloakContext);

  if (isByPassAuth(getCookie)) {
    return {
      keycloak: null,
      isAuthenticated: true,
      isAuthLoading: false,
      errorMessage: undefined,
    };
  }
  if (!context) {
    throw new Error('useKeycloak must be used within a KeycloakProvider');
  }

  return context;
}
