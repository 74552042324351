import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'utils/reactQuery';

export const getQueriesToInvalidate = (selectedProjectId: number | null) => [
  queryKeys.project(selectedProjectId).tvdForesite,
  queryKeys.project(selectedProjectId).foresiteCost,
  queryKeys.project(selectedProjectId).foresiteSummary,
  queryKeys.project(selectedProjectId).foresiteProgram,
];

export const useInvalidateTVDQueries = () => {
  const queryClient = useQueryClient();
  const invalidateTVDQueries = (selectedProjectId: number | null) => {
    getQueriesToInvalidate(selectedProjectId).forEach((queryKey) => {
      queryClient.invalidateQueries({
        queryKey,
      });
    });
  };

  return invalidateTVDQueries;
};
