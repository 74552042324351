import {
  CustomAttribute,
  CustomAttributeOption,
  CustomAttributeOptionForm,
  EntityCustomAttributeValue,
} from 'types/CustomAttributes';
import { uuidv4 } from 'utils/helpers';

export type CustomAttributeWithGroupId = CustomAttribute & { groupId: number };

export type CustomAttributeFormData = Partial<
  Omit<CustomAttributeWithGroupId, 'options'> & { options: CustomAttributeOptionForm[] }
>;

export const isCustomAttributeWithGroupId = (
  data: CustomAttributeWithGroupId | CustomAttributeFormData,
): data is CustomAttributeWithGroupId => {
  const hasOptions = data.type && ['SELECT', 'MULTISELECT', 'TAG'].includes(data.type);
  return Object.keys(data).every((key) =>
    key === 'description' || hasOptions
      ? data[key as keyof typeof data] !== undefined
      : key === 'options',
  );
};

export const MIN_REQUIRED_OPTIONS = 2;
export const MIN_REQUIRED_OPTIONS_HELPER_TEXT = `At least ${MIN_REQUIRED_OPTIONS} options are required`;

export const createNewOption = (value = ''): CustomAttributeOptionForm => ({
  option_id: uuidv4(),
  option_value: value,
});

export const createNewOptions = (amount = 2) =>
  Array.from({ length: amount }).map(() => createNewOption());

export const serializeOptions = (
  options?: CustomAttributeOptionForm[],
): (Omit<CustomAttributeOption, 'option_id'> & {
  option_id: CustomAttributeOption['option_id'] | null;
})[] =>
  options
    ? options
        .filter(({ option_value }) => !!option_value)
        .map(({ option_id, option_value }) => ({
          option_id: typeof option_id === 'string' ? null : option_id,
          option_value,
        }))
    : [];


export const parseCustomAttributes = (customAttributes?: EntityCustomAttributeValue[]) => {
  return customAttributes?.map((attribute) => {
    let value;
    if (attribute.type === 'TAG') {
      value = serializeOptions(attribute.value as CustomAttributeOptionForm[]);

      return {
        ...attribute,
        value,
      };
    }
    return attribute;
  })
};

export const requiresConfigurationUpdate = (customAttributes?: EntityCustomAttributeValue[]) => {
  return customAttributes?.find((attribute) => {
    if (attribute.type === 'TAG') {
      return (attribute.value as CustomAttributeOptionForm[] | undefined)?.filter(ca => !!ca.option_value).find((v) => typeof v.option_id === 'string');
    }
    return false;
  });
};