import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useActiveMilestone } from 'features/Estimate/hooks/milestone';
import { replaceProjectIdPath, useRedirectHome } from 'hooks/navigate';
import { atomWithStorage } from 'jotai/utils';
import { isNil, pick } from 'lodash-es';
import {
  DetailFieldOption,
  DetailFieldType,
  Project,
  ProjectStatus,
} from 'types/Project';
import log from 'loglevel';
import { History, PrivateURL } from 'Urls';
import { useScenariosFocus } from 'features/Foresite/hooks/scenarios';
import { useItemsFocus } from 'features/Foresite/hooks/itemsFocus';
import { isLegacyOrDraftProject } from 'mappings/project';
import { queryKeys } from 'utils/reactQuery';
import { useInvalidateTVDQueries } from 'features/TargetValueDesign/hooks/useInvalidateTVDQueries';
import { useProject } from './useProject';
import { useTenantFeatureFlags } from 'hooks/useTenantFeatureFlags';
import * as Sentry from '@sentry/react';
import { parseCustomAttributes, requiresConfigurationUpdate } from 'features/custom-attributes/components/CustomAttributeDetailsFlyout/helper';

export const useProjects = () => {
  const projectsQuery = useQuery({
    queryKey: queryKeys.projects.all,
    queryFn: ({ signal }) =>
      ApiService.get(Resources.ALL_PROJECTS, { signal }).then(
        (res) => res.data as Project[],
      ),

    staleTime: Infinity,
  });

  return { projectsQuery };
};

const selectedProjectIdAtom = atomWithStorage<number | null>(
  'concntric-selected-project-id',
  null,
);
selectedProjectIdAtom.debugLabel = 'selectedProjectIdAtom';

export const useUpdateProjectStatus = () => {
  const queryClient = useQueryClient();
  const { project } = useProject();

  const updateProjectStatusMutation = useMutation({
    mutationFn: ({
      projectId,
      projectStatus,
    }: {
      projectId: number;
      projectStatus: ProjectStatus;
    }) => {
      const endPoint = Resources.PROJECT_UPDATE_STATUS.replace(
        '<int:project_id>',
        projectId.toString(),
      );
      const payload = {
        // eslint-disable-next-line camelcase
        target_status: projectStatus,
      };

      return ApiService.patch(endPoint, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.projects.all });
    },
    onSettled: (response, _error, { projectId }) => {
      if (project && 'id' in project && String(projectId) === String(project.id)) {
        queryClient.setQueryData(
          queryKeys.project(response?.data.id).details,
          (prev: Project | undefined) =>
            prev ? { ...prev, status: response?.data.status } : response?.data,
        );
      }
    },
    onError: (error) => log.error(error instanceof Error ? error.message : error),
  });

  return { updateProjectStatusMutation };
};

export const useClearProjectData = () => {
  const { scenariosFocusClear } = useScenariosFocus();
  const { resetItemsFocus } = useItemsFocus();
  const { cleanActiveMilestone } = useActiveMilestone();

  const clearProjectData = () => {
    scenariosFocusClear();
    resetItemsFocus();
    cleanActiveMilestone();
  };

  return {
    clearProjectData,
  };
};

export const useSwitchProject = () => {
  const { clearProjectData } = useClearProjectData();
  const { redirectHome } = useRedirectHome();

  const switchToProject = (
    projectId: number,
    options?: { to?: string; redirect?: boolean },
  ) => {
    log.debug(
      'Switching to project ID: ',
      projectId,
      '. With redirect: ',
      options?.redirect,
    );

    clearProjectData();

    if (options?.to) {
      History.push(options.to);
    } else if (options?.redirect && redirectHome) {
      log.debug('Redirecting project home');
      redirectHome({ projectId });
    }
  };

  const redirectToProject = (projectId: number, projectStatus: ProjectStatus) => {
    switchToProject(projectId, {
      to: replaceProjectIdPath(
        projectStatus === 'DRAFT' ? PrivateURL.PROJECT_UPDATE : PrivateURL.OVERVIEW,
        projectId,
      ),
    });
  };
  return { switchToProject, redirectToProject };
};

type ProjectData = Omit<Partial<Project>, 'target_area'> & {
  id?: number | null;
  pictureFile?: File;
  target_area?: string;
};

const isNewProject = (projectData: ProjectData) =>
  !projectData || projectData.id === null || projectData.id === undefined;

export const useSaveProject = () => {
  const queryClient = useQueryClient();
  const { switchToProject } = useSwitchProject();
  const invalidateTVDQueries = useInvalidateTVDQueries();
  const {
    tenantFeatureFlags: { enable_custom_attributes: isCustomAttributesEnabled },
  } = useTenantFeatureFlags();

  const saveProjectMutation = useMutation({
    mutationFn: (projectData: ProjectData) => {
      let method;
      let endPoint;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      let serializedProjectData = projectData;
      if (isLegacyOrDraftProject(projectData)) {
        serializedProjectData = pick(projectData, [
          'id',
          'alias',
          'address',
          'status',
          'name',
          'code',
          'delivery_contract_type',
          'construction_category',
          'target_square_footage',
          'target_budget',
          'target_area',
          'picture',
          'pictureFile',
          'construction_start_date',
          'completion_date',
          'is_under_contract',
          'access_scope',
          'owner',
          'cm_firm',
          'architect_firm',
          'jv_partners',
          'groups',
          ...(isCustomAttributesEnabled ? ['custom_attributes'] : []),
        ] as (keyof ProjectData)[]);
      }


      if (isNewProject(serializedProjectData)) {
        method = ApiService.post;
        endPoint = isLegacyOrDraftProject(serializedProjectData)
          ? Resources.PROJECT_LEGACY
          : Resources.ALL_PROJECTS;
      } else {
        method = ApiService.patch;
        endPoint = isLegacyOrDraftProject(serializedProjectData)
          ? Resources.PROJECT_LEGACY_BY_ID.replace(
              '<int:pk>',
              serializedProjectData.id + '',
            )
          : Resources.PROJECT_BY_ID.replace('<int:pk>', serializedProjectData.id + '');
      }

      const form = new FormData();
      (
        Object.keys(serializedProjectData) as Array<keyof typeof serializedProjectData>
      ).forEach((key) => {
        if (key === 'pictureFile' && serializedProjectData.pictureFile) {
          form.append(
            'picture',
            serializedProjectData.pictureFile,
            serializedProjectData.pictureFile.name,
          );
        } else if (key === 'jv_partners') {
          serializedProjectData[key]?.forEach((partner) => {
            form.append(key, partner.toString());
          });
        } else if (key === 'custom_attributes') {
          const parsedCustomAttributes = parseCustomAttributes(serializedProjectData[key]);
          try {
            form.append(key, JSON.stringify(parsedCustomAttributes));
          } catch (error) {
            Sentry.captureException(error);
          }
        } else if (
          !isNil(serializedProjectData[key]) &&
          key !== 'picture' &&
          key !== 'groups'
        ) {
          form.append(key, (serializedProjectData[key] as number | string).toString());
        } else if (key === 'groups') {
          const groups = serializedProjectData[key] as number[] | null;
          if (!groups?.length) {
            form.append(key, '');
          } else {
            groups.forEach((group) => {
              form.append(key, group.toString());
            });
          }
        } else if (key === 'completion_date') {
          // At this point, the value of the key is null and we send it to the API as the empty string
          form.append(key, '');
        }
      });
      return method(endPoint, form, config).then((res) => res.data);
    },
    onSuccess: (responseData: Project, projectData) => {
      if (isNewProject(projectData)) {
        switchToProject(responseData.id, {
          to: replaceProjectIdPath(PrivateURL.OVERVIEW, responseData.id),
        });
      } else {
        queryClient.setQueryData(queryKeys.project(responseData.id).details, () => ({
          ...responseData,
          status: projectData.status || responseData.status,
        }));
      }
      queryClient.invalidateQueries({ queryKey: queryKeys.projects.all });
      queryClient.removeQueries({
        predicate: (query) =>
          query.queryKey[0] === 'project' &&
          query.queryKey[1] === responseData.id &&
          query.queryKey[2] === 'calibrateByProject',
      });
      if (requiresConfigurationUpdate(projectData.custom_attributes)) {
        queryClient.removeQueries({
          queryKey: queryKeys.customAttributes({ entity: 'PROJECT' }).attributesConfigurations,
        });
      }
      queryClient.invalidateQueries({ queryKey: queryKeys.recentCategories });
      invalidateTVDQueries(responseData.id);
    },
    onError: (error) => log.error(error instanceof Error ? error.message : error),
  });
  return { saveProjectMutation };
};

export const useCreateNewDetailFieldValue = () => {
  const queryClient = useQueryClient();
  const createNewDetailFieldValueMutation = useMutation({
    mutationFn: (newValue: { type: DetailFieldType; name: string }) => {
      return ApiService.post(Resources.PROJECT_DETAIL_FIELD, newValue).then(
        (res) => res.data as DetailFieldOption,
      );
    },
    onMutate: async (newValue) => {
      const queryKey = queryKeys.projectDetailField(newValue.type);
      await queryClient.cancelQueries({ queryKey });
      const previousDetailValues: DetailFieldOption[] | undefined =
        queryClient.getQueryData(queryKey);
      if (previousDetailValues) {
        queryClient.setQueryData(queryKey, (oldDetailValues?: DetailFieldOption[]) =>
          oldDetailValues?.concat({ ...newValue, id: 0 }),
        );
      }

      return previousDetailValues;
    },
    onError: (error, newValue, previousDetailValues) => {
      if (previousDetailValues) {
        queryClient.setQueryData(
          queryKeys.projectDetailField(newValue.type),
          previousDetailValues,
        );
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: (_data, _error, newValue) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.projectDetailField(newValue.type),
      });
    },
  });
  return {
    createNewDetailFieldValueMutation,
  };
};
