import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  IconButton,
  Popover,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { PrivateURL } from 'Urls';
import { circleButtonStyles } from 'components/NavBarButton/styles';
import { Permissions } from 'features/Auth/Permissions';
import usePermissions from 'features/Auth/hook/usePermissions';
import { useCreateGenericAnalysis } from 'features/ConceptLab/hooks/analysis';
import { useMixpanel } from 'hooks/useMixpanel';
import { useTenantFeatureFlags } from 'hooks/useTenantFeatureFlags';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { joinSx } from 'utils/helpers';
import { NavBarPopoverButton } from './NavBarPopoverButton';

type NewEntityButtonProps = {
  sx: SxProps<Theme>;
};

export const NewEntityButton = (props: NewEntityButtonProps) => {
  const {
    tenantFeatureFlags: { concept_lab: isConceptLabEnabled },
  } = useTenantFeatureFlags();

  return isConceptLabEnabled ? (
    <NewMenuButton sx={props.sx} />
  ) : (
    <NewProjectButton sx={props.sx} />
  );
};

const NewMenuButton = ({ sx }: { sx: SxProps<Theme> }) => {
  const { HasAnyPerms, HasPerm } = usePermissions();
  const { createGenericAnalysis } = useCreateGenericAnalysis();
  const { mixpanel } = useMixpanel();
  const popoverAnchor = useRef<HTMLDivElement | null>(null);
  const [openedPopover, setOpenedPopover] = useState(false);

  const buttonSx = joinSx({ borderRadius: '16px', px: 1.5 }, sx);

  const shouldShowButtonText = useMediaQuery((theme: Theme) => theme.breakpoints.up(850));

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  return (
    <HasAnyPerms
      permissions={[Permissions.ADD_PROJECT, Permissions.ADD_CONCEPT_LAB_ANALYSIS]}
    >
      <Box
        ref={popoverAnchor}
        aria-owns="new-button-menu"
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        {shouldShowButtonText ? (
          <Button
            data-testid="new-button"
            variant="blueGhost"
            size="small"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            sx={buttonSx}
          >
            Create
          </Button>
        ) : (
          <Tooltip title="Create a Project or a Concept Analysis">
            <IconButton
              data-testid="new-button"
              sx={joinSx(
                circleButtonStyles,
                { color: 'primary.main', mr: 0, px: '8px !important' },
                buttonSx,
              )}
              size="small"
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Popover
        id={'create-menu'}
        sx={{ pointerEvents: 'none' }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={popoverLeave}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
          sx: {
            pointerEvents: 'auto',
            minWidth: 220,
            overflow: 'auto',
          },
        }}
      >
        <HasPerm permission={Permissions.ADD_PROJECT}>
          <NavBarPopoverButton
            to={PrivateURL.PROJECT_NEW_OPTIONS}
            onClick={() => {
              mixpanel.click('new project', 'top navigation bar');
              popoverLeave();
            }}
          >
            <Typography variant="textDefaultSemiBold">Create Project</Typography>
          </NavBarPopoverButton>
        </HasPerm>
        <HasPerm permission={Permissions.ADD_CONCEPT_LAB_ANALYSIS}>
          <NavBarPopoverButton
            onClick={() => {
              createGenericAnalysis();
              popoverLeave();
            }}
          >
            <Typography variant="textDefaultSemiBold">Create Analysis</Typography>
          </NavBarPopoverButton>
        </HasPerm>
      </Popover>
    </HasAnyPerms>
  );
};

type NewProjectButtonProps = {
  sx: SxProps<Theme>;
  alwaysShowText?: boolean;
};

const NewProjectButton = ({ sx, alwaysShowText = false }: NewProjectButtonProps) => {
  const { HasPerm } = usePermissions();
  const { mixpanel } = useMixpanel();
  const shouldShowButtonText =
    useMediaQuery((theme: Theme) => theme.breakpoints.up(850)) || alwaysShowText;
  const buttonSx = joinSx({ borderRadius: '16px', px: 1.5 }, sx);
  const commonProps = {
    component: Link,
    to: PrivateURL.PROJECT_NEW_OPTIONS,
    onClick: () => {
      mixpanel.click('new project', 'top navigation bar');
    },
  };
  const icon = <FontAwesomeIcon icon={faPlus} />;
  const dataTestId = 'new-project';
  const buttonText = 'Create Project';
  return (
    <HasPerm permission={Permissions.ADD_PROJECT}>
      {shouldShowButtonText ? (
        <Button
          data-testid={dataTestId}
          variant="blueGhost"
          size="small"
          startIcon={icon}
          {...commonProps}
          sx={buttonSx}
        >
          {buttonText}
        </Button>
      ) : (
        <Tooltip title={buttonText}>
          <IconButton
            data-testid={dataTestId}
            sx={joinSx(
              circleButtonStyles,
              { color: 'primary.main', mr: 0, px: '8px !important' },
              buttonSx,
            )}
            size="small"
            {...commonProps}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}
    </HasPerm>
  );
};
