import { useQueryClient } from '@tanstack/react-query';
import { useClearProjectData } from 'features/Projects/hook/project';
import { useCurrentUser } from './useCurrentUser';
import { clearSession } from './sessionHelpers';
import { useKeycloak } from './useKeycloack';

const USERNAME_LS_KEY = 'concntric-login-last-username';

const useClearCacheData = () => {
  const queryClient = useQueryClient();
  const clearCacheData = () => {
    queryClient.cancelQueries();
    queryClient.clear();
  };
  return {
    clearCacheData,
  };
};

export const useSessionActions = () => {
  const { clearProjectData } = useClearProjectData();
  const storedUsername = localStorage.getItem(USERNAME_LS_KEY);
  const { clearCurrentUser } = useCurrentUser();
  const { clearCacheData } = useClearCacheData();

  const { keycloak } = useKeycloak();

  const clearSessionData = async () => {
    clearSession();
    clearProjectData();
    clearCurrentUser();
    clearCacheData();
    return keycloak?.logout({
      logoutMethod: 'POST',
      redirectUri: window.location.origin,
    });
  };

  const logout = async () => {
    return clearSessionData();
  };

  // return { clearSessionData, storedUsername, login, logout };
  return { clearSessionData, storedUsername, logout };
};
