import { useCompanySettings } from 'features/Company/hooks/useCompanySettings';
import { useProjectSettings } from 'features/Settings/hooks/settings';
import { AreaMetricOptions } from './utils';

export const useDefaultProjectAreaMetric = () => {
  const { setting: tenantSettings } = useCompanySettings();

  const { setting: projectSettings } = useProjectSettings();

  const areaMetricValue =
    projectSettings?.measurement_preferences.area ||
    tenantSettings?.measurement_preferences.area ||
    'sq_ft';

  return (
    AreaMetricOptions.find((option) => option.value === areaMetricValue) ??
    AreaMetricOptions[0]
  );
};

export const useDefaultAreaMetric = () => {
  const { setting: tenantSettings } = useCompanySettings();

  const areaMetricValue = tenantSettings?.measurement_preferences.area || 'sq_ft';

  return (
    AreaMetricOptions.find((option) => option.value === areaMetricValue) ??
    AreaMetricOptions[0]
  );
};
