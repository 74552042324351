import { useAtom, WritableAtom } from 'jotai';

import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { useCallback, useEffect } from 'react';
import { RESET } from 'jotai/utils';
import { isEqual, isObject } from 'lodash-es';

type UseAtomWithUserIdType<T, K> = (
  atom: K,
  defaultValue?: T,
) => [value: T, setValue: (value: T) => void];

type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

type AtomWithStorage<V> = WritableAtom<V, [SetStateActionWithReset<V>], V>;
type AtomValue =
  | { isStorageAtomValue: boolean; [key: number]: unknown | null }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
  | null;
export const useAtomWithUserId: UseAtomWithUserIdType<AtomValue, AtomWithStorage<AtomValue>> = (
  atom,
  defaultValue = null,
) => {
  const [atomData, setAtomData] = useAtom(atom) as unknown as [
    atomData: AtomValue,
    setAtomData: (value: unknown) => void,
  ];
  const { currentUser } = useCurrentUser();

  const setValue = useCallback(
    (value: unknown) => {
      if (currentUser?.id) {
        const prevData = atomData?.isStorageAtomValue ? atomData : {};
        const updatedData = {
          ...prevData,
          [currentUser.id]: value,
          isStorageAtomValue: true,
        };
        if (isEqual(updatedData, atomData)) return;

        setAtomData(updatedData);
      }
    },
    [atomData, currentUser?.id, setAtomData],
  );

  // To set the initialValue
  useEffect(() => {
    if (atomData !== undefined && !atomData?.isStorageAtomValue) {
      setValue(atomData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atomData]);
  const value =
    currentUser?.id &&
    isObject(atomData) &&
    atomData[currentUser.id as keyof typeof atomData] !== undefined
      ? atomData[currentUser.id as keyof typeof atomData]
      : defaultValue;
  return [value, setValue];
};
