import { scan, getReport } from 'react-scan'; // import this BEFORE react

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import log from 'loglevel';

import { LicenseInfo } from '@mui/x-license';
import { Router, Switch } from 'react-router-dom';
import { Layout, History } from 'Urls';

import 'keen-slider/keen-slider.min.css';

import theme from './style/theme';

import GA from 'services/GoogleAnalytics';

import { Sentry } from 'services/Sentry';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Provider as JotaiProvider } from 'jotai';

import { queryClient } from 'utils/reactQuery';
import { IntercomProvider } from 'react-use-intercom';
import { PrivateRoute } from 'components/PrivateRoute';
import { PublicRoute } from 'components/PublicRoute';
import { isDev, isMaintenance, isProd } from 'utils/environment';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UserGuiding } from './services/UserGuiding';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import PwaProvider from 'components/PwaProvider/PwaProvider';
import { Init } from 'components/Init/Init';
import { MaintenanceMode } from 'components/Maintenance/Maintenance';
import { AtomsProvider } from 'components/AtomsProvider/AtomProvider';
import { KeycloakProvider } from 'features/Auth/components/KeycloakProvider';
import { getCurrentTenantSlug } from 'utils/helpers';

if (isDev()) {
  setUseWhatChange({ active: true });
  const { protocol, host, hostname } = new URL(window.location.href);
  const hostnameSubstrings = hostname.split('.');
  if (hostnameSubstrings.length === 1 && hostnameSubstrings[0] === 'localhost') {
    window.location.replace(`${protocol}//app.${host}`);
  }
}

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

const logLevel = import.meta.env.VITE_LOG_LEVEL || 'info';
log.setLevel(logLevel);
window.log = log;

const realm = getCurrentTenantSlug() ?? undefined;
const configKeycloak = {
  url: import.meta.env.VITE_SSO_AUTH_URL,
  realm,
  clientId: import.meta.env.VITE_SSO_CLIENT_ID ?? '',
};

if (
  typeof window !== 'undefined' &&
  isDev() &&
  import.meta.env.VITE_ENABLE_REACT_SCAN === 'true'
) {
  window.getReport = getReport;
  scan({
    enabled: true,
    log: true,
  });
}

const getApp = () => {
  if (isMaintenance()) {
    return <MaintenanceMode />;
  }
  return (
    <Router history={History}>
      <AtomsProvider>
        <UserGuiding />
        {isProd() && GA.init() && <GA.RouteTracker />}
        <Init />
        <Switch>
          <PublicRoute path={Layout.PUBLIC} />
          <PublicRoute path={Layout.DOC} />
          <KeycloakProvider
            keycloakConfig={configKeycloak}
            initOptions={{
              onLoad: 'check-sso',
              enableLogging: isDev(),
            }}
          >
            <PrivateRoute path={Layout.PRIVATE} />
          </KeycloakProvider>
        </Switch>
      </AtomsProvider>
    </Router>
  );
};

root.render(
  <StrictMode>
    <IntercomProvider appId={import.meta.env.VITE_INTERCOM}>
      <PwaProvider>
        <Sentry>
          <JotaiProvider>
            <QueryClientProvider client={queryClient}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <DndProvider backend={HTML5Backend}>{getApp()}</DndProvider>
                </ThemeProvider>
              </LocalizationProvider>
              {import.meta.env.VITE_E2E !== 'true' ? (
                <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
              ) : null}
            </QueryClientProvider>
          </JotaiProvider>
        </Sentry>
      </PwaProvider>
    </IntercomProvider>
  </StrictMode>,
);
