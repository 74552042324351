import { useTheme } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { History, PrivateURL } from 'Urls';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useDefaultAreaMetric } from 'components/AreaInput/hooks';
import { useCompanySettings } from 'features/Company/hooks/useCompanySettings';
import { useProjectCustomCostGroupDefinition } from 'features/Company/hooks/useProjectCustomCostGroupDefinition';
import { useSwitchProject } from 'features/Projects/hook/project';
import { replaceProjectIdPath, useGetProjectHomePage } from 'hooks/navigate';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { atom, useAtom } from 'jotai';
import { round } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { ProjectComparison, ProjectComparisonComponent } from 'types/Calibrate';
import { JSONValue } from 'types/Common';
import { getProjectNormalizationCoefficient } from 'utils/projects';
import { queryKeys } from 'utils/reactQuery';
import {
  AnalysisVariant,
  AnalysisVariantConfiguration,
  AnalysisVariantCosts,
  AnalysisVariantSummary,
  ConceptAnalysis,
} from '../type';
import { useSelectedVariantId } from './state';

export function useConceptLabAnalysis() {
  const conceptAnalysisQuery = useQuery({
    queryKey: queryKeys.conceptLab,
    queryFn: ({ signal }) => {
      const endPoint = Resources.CONCEPT_ANALYSIS_LIST;
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ConceptAnalysis[],
      );
    },
    refetchOnWindowFocus: false,
  });
  return { conceptAnalysisQuery };
}

export function useConceptLabAnalysisById({ id }: { id: number }) {
  const conceptAnalysisByIdQuery = useQuery({
    queryKey: queryKeys.conceptAnalysis(id).details,
    queryFn: ({ signal }) => {
      const endPoint = Resources.CONCEPT_ANALYSIS_BY_ID.replace(
        '<int:analysis_pk>',
        id.toString(),
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as ConceptAnalysis,
      );
    },
    refetchOnWindowFocus: false,
  });
  return { conceptAnalysisByIdQuery };
}

export const useSaveConceptLabAnalysis = () => {
  const queryClient = useQueryClient();

  const saveConceptLabAnalysisMutation = useMutation({
    mutationFn: ({ analysis }: { analysis: Partial<ConceptAnalysis> }) => {
      if (analysis.id) {
        const endPoint = Resources.CONCEPT_ANALYSIS_BY_ID.replace(
          '<int:analysis_pk>',
          analysis.id.toString(),
        );
        return ApiService.patch(endPoint, analysis).then((res) => res.data);
      }
      return ApiService.post(Resources.CONCEPT_ANALYSIS_LIST, analysis).then(
        (res) => res.data,
      );
    },
    onSettled: (_, _error, { analysis }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptLab,
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptAnalysis(analysis?.id).details,
      });
    },
  });

  return {
    saveConceptLabAnalysisMutation,
  };
};

export const useClearConceptLabAnalysisData = () => {
  const queryClient = useQueryClient();

  const clearConceptLabAnalysisDataMutation = useMutation({
    mutationFn: ({ analysis }: { analysis: Pick<ConceptAnalysis, 'id'> }) => {
      if (!analysis.id) throw Error('Analysis is mandatory');

      const endPoint = Resources.CLEAR_CONCEPT_ANALYSIS_BY_ID.replace(
        '<int:analysis_pk>',
        analysis.id.toString(),
      );
      return ApiService.post(endPoint, analysis).then((res) => res.data);
    },
    onSettled: (_, _error, { analysis }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptLab,
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptAnalysis(analysis?.id).details,
      });
    },
  });

  return {
    clearConceptLabAnalysisDataMutation,
  };
};

export const useCreateGenericAnalysis = () => {
  const { saveConceptLabAnalysisMutation } = useSaveConceptLabAnalysis();
  const { errorToast } = useToastDialogs();
  const { customCostGroupDefinitionsQuery } = useProjectCustomCostGroupDefinition();
  const { setting } = useCompanySettings();
  const defaultAreaMetric = useDefaultAreaMetric();

  const formInitialValues = {
    name: 'Analysis',
    description: '',
    definition:
      setting?.defaultFormat || customCostGroupDefinitionsQuery.data?.[0]?.id || 1,
    construction_category: null,
    type: 'BY_COMPONENTS',
    area: {
      value: 0,
      unit: defaultAreaMetric.value ?? 'sq_ft',
    },
    base_unit_count: 0,
    default_analysis_variant: {
      name: 'First Component set',
    },
  };

  const createGenericAnalysis = () => {
    saveConceptLabAnalysisMutation.mutate(
      { analysis: formInitialValues as unknown as ConceptAnalysis },
      {
        onSettled: (data) => {
          History.push(
            PrivateURL.CONCEPT_LAB_ANALYSIS_BY_ID.replace(':analysisId', String(data.id)),
          );
        },
        onError: () => {
          errorToast({
            text: `Analysis could not be created`,
          });
        },
      },
    );
  };

  return { createGenericAnalysis };
};

export const useDeleteConceptLabAnalysis = () => {
  const queryClient = useQueryClient();

  const deleteConceptLabAnalysisMutation = useMutation({
    mutationFn: ({ analysisId }: { analysisId: number }) => {
      const endPoint = Resources.CONCEPT_ANALYSIS_BY_ID.replace(
        '<int:analysis_pk>',
        analysisId.toString(),
      );
      return ApiService.delete(endPoint).then((res) => res.data);
    },
    onSettled: (_, _error) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptLab,
      });
    },
  });

  return {
    deleteConceptLabAnalysisMutation,
  };
};

export const useConceptLabVariantById = ({ id }: { id: number | null }) => {
  const conceptLabVariantByIdQuery = useQuery({
    queryKey: queryKeys.conceptAnalysisVariant(id),
    queryFn: ({ signal }) => {
      const endPoint = Resources.CONCEPT_VARIANTS_BY_ID.replace(
        '<int:variant_pk>',
        (id ?? '').toString(),
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) => res.data as AnalysisVariantCosts,
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
  return { conceptLabVariantByIdQuery };
};

export const useSaveConceptLabVariant = () => {
  const queryClient = useQueryClient();

  const saveConceptLabVariantMutation = useMutation({
    mutationFn: ({ variant }: { variant: Partial<AnalysisVariant> }) => {
      const endPoint = variant.id
        ? Resources.CONCEPT_VARIANTS_BY_ID.replace(
            '<int:variant_pk>',
            variant.id.toString(),
          )
        : Resources.CONCEPT_VARIANTS_LIST;

      const method = variant.id ? ApiService.put : ApiService.post;

      return method(endPoint, variant).then((res) => res.data);
    },
    onMutate: async ({ variant }) => {
      const queryKey = queryKeys.conceptAnalysisVariant(variant.id);
      await queryClient.cancelQueries({ queryKey });

      const previousVariantData = queryClient.getQueryData(
        queryKey,
      ) as AnalysisVariantCosts;
      if (previousVariantData) {
        queryClient.setQueryData(queryKey, {
          ...previousVariantData,
          ...variant,
        });
      }

      return { previousVariantData };
    },
    onError: (error, { variant }, context) => {
      if (context?.previousVariantData) {
        queryClient.setQueryData(
          queryKeys.conceptAnalysisVariant(variant.id),
          context.previousVariantData,
        );
      }
      log.error(error instanceof Error ? error.message : error);
    },
    onSettled: (_, _error, { variant }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptAnalysis(variant.analysis).details,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptLab,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptAnalysisVariant(variant.id),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptAnalysisVariantSummary(variant.id),
      });
    },
  });

  return {
    saveConceptLabVariantMutation,
  };
};

export const useDeleteConceptLabVariant = () => {
  const queryClient = useQueryClient();

  const deleteConceptLabVariantMutation = useMutation({
    mutationFn: ({ variant }: { variant: AnalysisVariant }) => {
      const endPoint = Resources.CONCEPT_VARIANTS_BY_ID.replace(
        '<int:variant_pk>',
        variant.id.toString(),
      );
      return ApiService.delete(endPoint).then((res) => res.data);
    },
    onSettled: (_, _error, { variant }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptAnalysis(variant.analysis).details,
      });
    },
  });

  return {
    deleteConceptLabVariantMutation,
  };
};

export const useConceptLabVariantSummary = ({ id }: { id: number | null }) => {
  const theme = useTheme();
  const { normalizeByCustomCoefficients, normalizeByLocation, normalizeByTime } =
    useVariantNormalization();
  const conceptLabVariantSummaryQuery = useQuery({
    queryKey: queryKeys.conceptAnalysisVariantSummary(id),
    queryFn: ({ signal }) => {
      const endPoint = Resources.CONCEPT_VARIANT_SUMMARY_BY_ID.replace(
        '<int:variant_pk>',
        (id ?? '').toString(),
      );
      return ApiService.get(endPoint, { signal }).then((res) => {
        const projects = res.data?.projects ?? [];
        return {
          ...(res.data ?? {}),
          projects,
          playground: {
            ...(res.data?.playground ?? {}),
            area: res.data?.playground?.area?.value ?? 0,
          },
        } as AnalysisVariantSummary;
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  const projects = useMemo(() => {
    if (!conceptLabVariantSummaryQuery.data) return [];

    return conceptLabVariantSummaryQuery.data.projects.map(
      (project: ProjectComparison, index: number) => {
        const cost = round(
          project.cost *
            getProjectNormalizationCoefficient(
              project,
              normalizeByCustomCoefficients,
              normalizeByLocation,
              normalizeByTime,
            ),
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const gsf = project.area?.value ?? 1;
        const cost_per_selected_unit = cost / gsf;
        return {
          ...project,
          coefficients: project.coefficients ?? {},
          components: conceptLabVariantSummaryQuery.data.components.filter(
            (c: ProjectComparisonComponent) => c.project === project.id,
          ),
          cost,
          color: theme.palette.other.getProjectComparisonColor(index),
          cost_per_selected_unit,
          gsf,
        } as unknown as ProjectComparison;
      },
    ) as ProjectComparison[];
  }, [
    conceptLabVariantSummaryQuery.data,
    normalizeByCustomCoefficients,
    normalizeByLocation,
    normalizeByTime,
    theme.palette.other,
  ]);

  return {
    conceptLabVariantSummaryQuery: {
      ...conceptLabVariantSummaryQuery,
      data: { ...conceptLabVariantSummaryQuery.data, projects } as AnalysisVariantSummary,
    },
  };
};

export const usePromoteConceptToProject = () => {
  const queryClient = useQueryClient();
  const { switchToProject } = useSwitchProject();
  const { getProjectHomePageStep } = useGetProjectHomePage();

  const promoteConceptToProjectMutation = useMutation({
    mutationFn: ({
      analysisId,
      projectData,
    }: {
      analysisId: number;
      projectData: JSONValue;
    }) => {
      return ApiService.post(
        Resources.CONCEPT_LAB_ANALYSIS_PROMOTE.replace(
          '<int:analysis_pk>',
          analysisId.toString(),
        ),
        projectData,
      ).then((res) => res.data);
    },
    onSettled: async (data, _error) => {
      switchToProject(data.id, {
        to: replaceProjectIdPath(await getProjectHomePageStep(data), data.id),
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.conceptLab,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.projects.lean,
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.projects.all,
      });
    },
  });

  return {
    promoteConceptToProjectMutation,
  };
};

const isNormalizationFlyoutOpenAtom = atom<boolean>(false);

export const defaultNormalizationData: AnalysisVariantConfiguration = {
  normalization_index_id: null,
  normalize_by_custom_coefficients: null,
  normalize_by_time: null,
  normalize_by_location: null,
  normalization_type: 'basic',
  normalize_by_month: null,
  normalize_by_custom_index: null,
  plot_normalization_factors: false,
  calculation_type_applied: null,
  calculation_values: null,
};

export const useVariantNormalization = () => {
  const { selectedVariantId } = useSelectedVariantId();
  const {
    conceptLabVariantByIdQuery: { data: variant },
  } = useConceptLabVariantById({ id: selectedVariantId });
  const { saveConceptLabVariantMutation } = useSaveConceptLabVariant();

  const saveNormalizationData = useCallback(
    (data: Partial<AnalysisVariant['configuration']>) => {
      saveConceptLabVariantMutation.mutate({
        variant: {
          ...variant,
          configuration: {
            ...defaultNormalizationData,
            ...(variant?.configuration ?? {}),
            ...data,
          },
        },
      });
    },
    [saveConceptLabVariantMutation, variant],
  );

  const [isNormalizationFlyoutOpen, setIsNormalizationFlyoutOpen] = useAtom(
    isNormalizationFlyoutOpenAtom,
  );

  const toggleNormalizeByCustomCoefficients = useCallback(
    () =>
      saveNormalizationData({
        normalize_by_custom_coefficients:
          !variant?.configuration?.normalize_by_custom_coefficients,
      }),
    [saveNormalizationData, variant],
  );
  const toggleNormalizeByTime = useCallback(
    () =>
      saveNormalizationData({
        normalize_by_time: !variant?.configuration?.normalize_by_time,
      }),
    [saveNormalizationData, variant],
  );
  const toggleNormalizeByLocation = useCallback(
    () =>
      saveNormalizationData({
        normalize_by_location: !variant?.configuration?.normalize_by_location,
      }),
    [saveNormalizationData, variant],
  );
  const togglePlotNormalizationFactors = useCallback(
    () =>
      saveNormalizationData({
        plot_normalization_factors: !variant?.configuration?.plot_normalization_factors,
      }),
    [saveNormalizationData, variant],
  );
  const selectIndexId = useCallback(
    (indexId: number | null) =>
      saveNormalizationData({
        normalization_index_id: indexId,
      }),
    [saveNormalizationData, variant],
  );
  const toggleNormalizeByMonth = useCallback(
    () =>
      saveNormalizationData({
        normalize_by_month: !variant?.configuration?.normalize_by_month,
      }),
    [saveNormalizationData, variant],
  );

  const toggleNormalizationType = useCallback(
    () =>
      saveNormalizationData({
        normalization_type:
          variant?.configuration?.normalization_type === 'basic' ? 'custom' : 'basic',
      }),
    [saveNormalizationData, variant],
  );

  const toggleNormalizeByCustomIndex = useCallback(
    () =>
      saveNormalizationData({
        normalize_by_custom_index: !variant?.configuration?.normalize_by_custom_index,
      }),
    [saveNormalizationData, variant],
  );

  return {
    isNormalizationFlyoutOpen,
    normalizeByCustomCoefficients:
      !!variant?.configuration?.normalize_by_custom_coefficients,
    normalizeByLocation: !!variant?.configuration?.normalize_by_location,
    normalizeByTime: !!variant?.configuration?.normalize_by_time,
    indexId: variant?.configuration?.normalization_index_id ?? null,
    normalizeByMonth: !!variant?.configuration?.normalize_by_month,
    plotNormalizationFactors: !!variant?.configuration?.plot_normalization_factors,
    normalizeByCustomIndex: !!variant?.configuration?.normalize_by_custom_index,
    normalizationType: variant?.configuration?.normalization_type ?? 'basic',
    setIsNormalizationFlyoutOpen,
    toggleNormalizeByCustomCoefficients,
    toggleNormalizeByTime,
    toggleNormalizeByLocation,
    togglePlotNormalizationFactors,
    selectIndexId,
    toggleNormalizeByMonth,
    toggleNormalizationType,
    toggleNormalizeByCustomIndex,
  };
};
