import { Box, Typography, Collapse, Divider } from '@mui/material';
import usePermissions from 'features/Auth/hook/usePermissions';
import { Permissions } from 'features/Auth/Permissions';
import { useLocation } from 'react-router-dom';

import { InviteMemberLinkItemAndModal } from './InviteMemberLinkItemAndModal';
import { RoleImpersonationLinkItemAndModal } from './RoleImpersonationLinkItemAndModal';
import { SideBarListItem } from './SideBarListItem';
import { useSectionRoutes } from './useSectionRoutes';
import { isLegacyOrDraftProject } from 'mappings/project';
import { useSidebar } from 'hooks/ui';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { useProject } from 'features/Projects/hook/useProject';
import { useTenantFeatureFlags } from 'hooks/useTenantFeatureFlags';
export const NavigationLinkItems = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const { isSidebarCollapsed } = useSidebar();
  const { project } = useProject();
  const location = useLocation();
  const { hasPerm } = usePermissions();
  const sectionsRoutes = useSectionRoutes();
  const {
    tenantFeatureFlags: { role_impersonation: isRoleImpersonationEnabled },
  } = useTenantFeatureFlags();

  return !selectedProjectId ? null : (
    <>
      {sectionsRoutes.map((section, sectionIndex) => {
        return (
          <Box key={sectionIndex} sx={{ mt: 1 }}>
            <Collapse in={!isSidebarCollapsed}>
              <Typography
                variant="textSmallBold"
                sx={{ textTransform: 'uppercase', ml: 3 }}
              >
                {section.sectionLabel}
              </Typography>
            </Collapse>
            {section.routes.map((route, routeIndex) => {
              const path = route.url.replace(':projectId', selectedProjectId.toString());
              const isCurrentPath = location.pathname === path;
              return (
                <SideBarListItem
                  key={routeIndex}
                  listIndex={routeIndex}
                  selected={isCurrentPath}
                  path={path}
                  icon={route.icon}
                  label={route.label}
                  emptyLabel={route.emptyLabel}
                  chipLabel={route.chipLabel}
                  isDataMissing={route.isDataMissing}
                />
              );
            })}
          </Box>
        );
      })}
      {hasPerm(Permissions.INVITE_MEMBERS_TO_PROJECT) &&
      !isLegacyOrDraftProject(project) ? (
        <>
          <Divider sx={{ borderColor: 'grey.500', mx: 1, mb: 1 }} />
          <InviteMemberLinkItemAndModal />
        </>
      ) : null}
      {hasPerm(Permissions.INVITE_MEMBERS_TO_PROJECT) &&
      isRoleImpersonationEnabled &&
      !isLegacyOrDraftProject(project) ? (
        <>
          <RoleImpersonationLinkItemAndModal />
        </>
      ) : null}
    </>
  );
};
