import { AreaMetric } from './type';

export const AreaMetricOptions = [
  { label: 'Square Feet', shortName: 'GSF', value: 'sq_ft' },
  { label: 'Square Meters', shortName: 'SQM', value: 'sq_m' },
  { label: 'Acres', shortName: 'AC', value: 'acre' },
  { label: 'Hectares', shortName: 'HA', value: 'hectare' },
] as const;

export const SettingsAreaMetricOptions = [
  { label: 'Square Feet', shortName: 'GSF', value: 'sq_ft' },
  { label: 'Square Meters', shortName: 'SQM', value: 'sq_m' },
] as const;

export const getAreaMetricLabel = (value: AreaMetric | '' | undefined) => {
  return AreaMetricOptions.find((option) => option.value === value)?.shortName ?? '';
};
