import { ReactNode, forwardRef } from 'react';
import { useAccessScopesTree } from 'features/ManageUsers/hooks/accessScope';
import {
  Select,
  MenuItem,
  SelectProps,
  Box,
  CircularProgress,
  InputLabel,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import { AccessScope, AccessScopeTree } from 'types/User';
import { keyBy, omit } from 'lodash-es';
import { getAccessScopeFullPath } from 'features/ManageUsers/utils/accessScopesTransforms';

type AccessScopeWithLevel = AccessScope & Pick<AccessScopeTree, 'level'>;

// If `filterByMemberId` is not set, we just add all nodes
// If not, we should filter the scopes that has not that member assigned
// All children nodes of a node that has the specified user are added
const getAccessScopeArray = (
  node: AccessScopeTree,
  filterByMemberId?: number,
  parentScopeHasMember?: boolean,
): AccessScopeWithLevel[] => {
  let accessScopes: AccessScopeWithLevel[] = [];

  const shouldAddNode =
    parentScopeHasMember ||
    !filterByMemberId ||
    node.members.some((member) => member.id === filterByMemberId);
  if (shouldAddNode) {
    accessScopes.push(omit(node, ['children']));
  }

  if (node.children) {
    accessScopes = accessScopes.concat(
      ...node.children.map((child) =>
        getAccessScopeArray(child, filterByMemberId, shouldAddNode),
      ),
    );
  }

  return accessScopes;
};

type AccessScopeSelectProps = SelectProps & {
  filterByMemberId?: number;
  helperText?: ReactNode;
  disableOptionIds?: number[];
};

export const AccessScopeSelect = forwardRef((props: AccessScopeSelectProps, ref) => {
  const {
    filterByMemberId,
    label,
    helperText,
    sx,
    placeholder,
    disableOptionIds,
    required,
    value,
    disabled,
    ...rest
  } = props;
  const { accessScopeTreeQuery } = useAccessScopesTree();

  const userOptions: AccessScopeTree[] = accessScopeTreeQuery.data
    ? getAccessScopeArray(accessScopeTreeQuery.data, filterByMemberId)
    : [];

  const allOptions: AccessScopeTree[] = accessScopeTreeQuery.data
    ? getAccessScopeArray(accessScopeTreeQuery.data)
    : [];

  const accessScopeTreeHash: Record<number, AccessScopeTree> = keyBy(allOptions, 'id');

  const isValueInUserOptions =
    !value || Boolean(userOptions.find((option) => option.id === value));

  const options = isValueInUserOptions ? userOptions : allOptions;

  return (
    <Box sx={sx}>
      {!!label && (
        <InputLabel required={required} sx={{ fontWeight: 600, color: 'grey.700' }}>
          {label}
        </InputLabel>
      )}
      <Tooltip
        arrow
        title={
          !disabled && !isValueInUserOptions
            ? 'User is not assigned to this Business Unit'
            : ''
        }
      >
        <Select
          ref={ref}
          sx={{
            '& .MuiSelect-select .notranslate::after': placeholder
              ? {
                  content: `"${placeholder}"`,
                  opacity: 0.42,
                }
              : {},
          }}
          value={accessScopeTreeQuery.isLoading ? 0 : value}
          renderValue={(accessScopeId) =>
            getAccessScopeFullPath(accessScopeId as number, accessScopeTreeHash)
          }
          disabled={disabled || !isValueInUserOptions}
          {...rest}
        >
          {accessScopeTreeQuery.isLoading ? (
            <MenuItem value={0}>
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <CircularProgress size={20} />
              </Box>
            </MenuItem>
          ) : (
            options.map((option, i, array) => {
              let paddingLevel = option.level;
              if (filterByMemberId) {
                // As we are filtering the scopes to keep only the ones that belongs to
                // filterByMemberId, we can not use node's level as padding, we have to
                // calculate each option padding
                paddingLevel = 0;

                if (option.level && i !== 0) {
                  const { padding } = array
                    .slice(0, i)
                    .reverse()
                    .reduce(
                      (acc, node) => {
                        if (acc.nextParent && node.id === acc.nextParent) {
                          return { nextParent: node.parent, padding: acc.padding + 1 };
                        }
                        return acc;
                      },
                      { nextParent: option.parent, padding: 0 } as {
                        nextParent: number | null;
                        padding: number;
                      },
                    );
                  paddingLevel = padding;
                }
              }

              return (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  sx={{ pl: 2 + paddingLevel }}
                  disabled={disableOptionIds && disableOptionIds.includes(option.id)}
                >
                  {option.name}
                </MenuItem>
              );
            })
          )}
        </Select>
      </Tooltip>
      {helperText && (
        <FormHelperText sx={rest.error ? { color: 'cautionRed.800' } : {}}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
});
