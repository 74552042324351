import {
  BaseDivisionComparison,
  ComparisonBaseUnit,
  ComparisonCoefficients,
  ProjectComparison,
} from './Calibrate';
import { CostGroup } from './CostGroup';
import { ComponentSeries, Foresite, ForeSiteChartSeries } from './ForeSite';
import { Setting } from './Setting';

export type DimensionSeries = ComponentSeries & {
  // For TVD we need a more general target value than 'component_target'.
  // It could be 'division_target' or 'element_target'.
  target: number | null;
  stretch_goal: number | null;
};

export type TargetValueDesign = Omit<Foresite, 'getPendingHighLow'> &
  Required<Pick<Foresite, 'getPendingHighLow'>> & {
    series: DimensionSeries[];
  };

export type DimensionMemberParams = {
  id: number;
  target: number | null;
  stretch_goal: number | null;
  tolerance: number;
  dimension_member_id?: number;
};

export type TVDCalibratePoint = {
  x: number;
  y: number;
};

export type TVDCalibrateDimensionMember = {
  // Dimension member id
  dimension_id: number;
  data: TVDCalibratePoint[];
};

export type TVDCalibrateProject = {
  project_id: number;
  project_name: string;
  base_unit: ComparisonBaseUnit;
  gsf: number;
  cost: number;
  dimension_members: TVDCalibrateDimensionMember[];
  coefficients: ComparisonCoefficients;
};

export type TVDCalibrateAverage = {
  members: Record<number, TVDCalibratePoint[]>; // dimension_id -> data
  general: TVDCalibratePoint[];
};

export type TVDCalibrate = {
  projects?: TVDCalibrateProject[];
  average?: TVDCalibrateAverage;
};

type TVDCalibrateSettingsConfig = {
  compareProjectIds?: number[];
};

export interface TVDCalibrateSettings extends Setting {
  key: 'tvd_calibrate';
  config: TVDCalibrateSettingsConfig;
}

export type TVDCalibrateSeriesOptionsType = ForeSiteChartSeries & {
  calibrateProject?: TVDCalibrateProject;
};

export type TVDCostGroupSystem = {
  id: number;
  name: string;
  cost_groups: CostGroup[];
};

export const TargetBudgetStatus = {
  noTargetSet: 'noTargetSet',
  onTarget: 'onTarget',
  aboveTarget: 'aboveTarget',
  aboveTargetWithinTolerance: 'aboveTargetWithinTolerance',
  belowTarget: 'belowTarget',
} as const;

export type BudgetAdjusted = {
  cost: number;
  sqft: number;
};

export type TVDChartType = 'compact' | 'roomy';

export type TVDStatus = keyof typeof TargetBudgetStatus;

export type DimensionMemberDetails = {
  id: number;
  name: string;
};

export type DimensionMemberDetailsWithData = {
  id: number;
  name: string;
  tolerance: number;
  member_param_id: number;
  target: number;
  stretch_goal: number;
  status: TVDStatus;
  statusMessage: string;
  isLoading: boolean;
};

export type StatusOption = { label: string; value: TVDStatus };

export type DivisionComparison = Omit<BaseDivisionComparison, 'projects'> & {
  member_param: DimensionMemberParams | undefined;
  average_cost_per_selected_unit: number;
  has_comparison_projects: boolean;
  formatted_avg: string;
  projects: ProjectComparison[];
};
