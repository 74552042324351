import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { Item, ItemPreview } from 'types/Item';
import log from 'loglevel';
import { queryKeys } from 'utils/reactQuery';
import { orderBy } from 'lodash-es';
import { atom, useAtom } from 'jotai';
import { FileWithPath } from 'react-dropzone';

import { betterParseInt } from 'utils/helpers';
import { useCallback, useMemo } from 'react';
import { useToastDialogs } from 'hooks/useToastDialogs';
import { useQueryParamState } from 'hooks/useQueryParamState';
import { urlQueryParams } from 'utils/constants';
import { EditingSubItem } from '../components/ItemDetailsModal/types';

export type ItemDetailsId = number | 'new' | undefined;

type ItemInitialInfo = {
  component: number | null;
  sub_items: EditingSubItem[];
};

const openItemDetailsFlyoutAtom = atom(false);
const itemDetailsIdFlyoutAtom = atom<ItemDetailsId>(undefined);
const itemInitialDataAtom = atom<ItemInitialInfo>({
  component: null,
  sub_items: [],
});
const openItemsImportDropzoneAtom = atom(false);

export const useItemDetailsFlyout = () => {
  const [isItemFlyoutOpen, setIsOpenItemFlyout] = useAtom(openItemDetailsFlyoutAtom);
  const [itemDetailsId, setItemDetails] = useAtom(itemDetailsIdFlyoutAtom);
  // TODO_PERF: Redirect to cloned item
  const [itemInitialData, setItemInitialData] = useAtom(itemInitialDataAtom);

  const [queryItemDetailsId, setQueryItemDetailsId] = useQueryParamState<string>({
    name: urlQueryParams.itemDetailsId,
  });

  const setItemDetailsId = useCallback(
    (id: number | string) => {
      setTimeout(() => {
        setIsOpenItemFlyout(true);
        const nextItemDetailsId = id !== 'new' ? betterParseInt(id) : id;
        setItemDetails(nextItemDetailsId as number | 'new');
      }, 0);
    },
    [setIsOpenItemFlyout, setItemDetails],
  );

  const closeItemFlyout = useCallback(() => {
    setIsOpenItemFlyout(false);
    setItemDetails(undefined);
    setItemInitialData({ component: null, sub_items: [] });
    if (queryItemDetailsId) setQueryItemDetailsId('');
  }, [queryItemDetailsId, setIsOpenItemFlyout, setItemDetails, setItemInitialData]);

  return {
    itemDetailsId,
    isItemFlyoutOpen,
    setItemDetailsId,
    closeItemFlyout,
    itemInitialData,
    setItemInitialData,
  };
};

type ItemsPreviewModal = {
  items: ItemPreview[];
  isOpen: boolean;
};

const itemPreviewAtom = atom<ItemsPreviewModal>({ items: [], isOpen: false });

export const useItemPreviewFlyout = () => {
  const [itemPreviewData, setItemPreviewData] = useAtom(itemPreviewAtom);

  const openPreviewModal = useCallback(() => {
    setItemPreviewData({ ...itemPreviewData, isOpen: true });
  }, [itemPreviewData, setItemPreviewData]);

  const closePreviewModal = useCallback(() => {
    setItemPreviewData({ items: [], isOpen: false });
  }, [setItemPreviewData]);

  const setItems = useCallback(
    (items: ItemPreview[]) => {
      setItemPreviewData({ ...itemPreviewData, items });
    },
    [itemPreviewData, setItemPreviewData],
  );

  return {
    ...itemPreviewData,
    setItemPreviewData,
    openPreviewModal,
    closePreviewModal,
    setItems,
  };
};

export const useItems = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const { selectedProjectId } = useSelectedProjectId();

  const itemsQuery = useQuery({
    queryKey: queryKeys.project(selectedProjectId).items,
    queryFn: ({ signal }) => {
      const endPoint = Resources.ALL_ITEMS_BY_PROJECT_ID.replace(
        '<int:project_pk>',
        selectedProjectId as unknown as string,
      );
      return ApiService.get(endPoint, { signal }).then(
        (res) =>
          orderBy(res.data, 'number', 'asc').map((item) => {
            if (item.status === 'VOID') {
              return {
                ...item,
                name: 'Void',
                due_date: null,
                priority: 'MEDIUM',
                estimated_sq_ft: 0,
                lower_limit: 0,
                upper_limit: 0,
              };
            } else {
              return item;
            }
          }) as Item[],
      );
    },
    enabled: !!selectedProjectId && enabled,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return {
    ...itemsQuery,
    items: useMemo(
      () => itemsQuery.data?.filter((item) => !item.is_deleted),
      [itemsQuery.data],
    ),
    allItems: itemsQuery.data,
    isItemsLoading: itemsQuery.isLoading,
    isItemsFetching: itemsQuery.isFetching,
    itemsRefetch: itemsQuery.refetch,
    isItemsRefetching: itemsQuery.isRefetching,
  };
};

export const useUpdateItems = () => {
  const queryClient = useQueryClient();

  const updateItemsMutation = useMutation({
    mutationFn: ({ items, projectId }: { items: Item[]; projectId: number | null }) => {
      const endPoint = Resources.ALL_ITEMS_BY_PROJECT_ID.replace(
        '<int:project_pk>',
        projectId as unknown as string,
      );

      return ApiService.patch(endPoint, { items }).then((res) => res.data as Item[]);
    },
    onMutate: async ({ items, projectId }) => {
      // Cancel any outgoing re-fetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: queryKeys.project(projectId).items });
      const previousItems = queryClient.getQueryData<Item[]>(
        queryKeys.project(projectId).items,
      );
      if (previousItems) {
        const newItems = Array.from(previousItems);
        items.forEach((item) => {
          const index = newItems.findIndex((c) => c.id === item.id);
          newItems[index] = { ...newItems[index], ...item };
        });

        queryClient.setQueryData(
          queryKeys.project(projectId).items,
          orderBy(newItems, 'item_order', 'asc'),
        );
      }
      return { previousItems };
    },
    onError: (error, { projectId }, context) => {
      if (context?.previousItems) {
        queryClient.setQueryData(
          queryKeys.project(projectId).items,
          context.previousItems,
        );
      }
      log.error(error instanceof Error ? error.message : error);
    },
  });

  return { updateItemsMutation };
};

type ImportItemsArgument = {
  file: FileWithPath;
};

export const useImportItems = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const { setItemPreviewData } = useItemPreviewFlyout();
  const { errorToast } = useToastDialogs();

  const importItemsMutation = useMutation({
    mutationFn: ({ file }: ImportItemsArgument) => {
      const form = new FormData();

      const endpoint = Resources.IMPORT_SCENARIO_ITEMS.replace(
        '<int:project_pk>',
        selectedProjectId as unknown as string,
      );

      form.append('file', file, file.name);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      return ApiService.post(endpoint, form, config).then((res) => {
        setItemPreviewData({
          isOpen: true,
          items: res.data,
        });
      });
    },
    onError: (error) => {
      errorToast({ text: 'The file format is not compatible' });
      log.error(error instanceof Error ? error.message : error);
    },
  });

  return { importItemsMutation };
};

export const useSaveItemBatch = () => {
  const queryClient = useQueryClient();
  const { selectedProjectId } = useSelectedProjectId();
  const { closePreviewModal } = useItemPreviewFlyout();

  const saveItemBatchMutation = useMutation({
    mutationFn: (items: ItemPreview[]) => {
      const endpoint = Resources.SAVE_ITEM_BATCH.replace(
        '<int:project_pk>',
        selectedProjectId as unknown as string,
      );

      return ApiService.post(endpoint, { items });
    },
    onSettled: (response) => {
      if (selectedProjectId) {
        (response?.data ?? []).forEach((item: Item) => {
          queryClient.removeQueries({ queryKey: queryKeys.item(item.id).details });
        });
        queryClient.removeQueries({
          queryKey: queryKeys.project(selectedProjectId).items,
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.project(selectedProjectId).scenarios,
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.project(selectedProjectId).foresiteSummary,
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.project(selectedProjectId).foresiteCost,
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.project(selectedProjectId).foresiteProgram,
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.project(selectedProjectId).tvdForesite,
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.project(selectedProjectId).tvdCalibrate,
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.project(selectedProjectId).tvdForesiteBudgetAdjusted,
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.project(selectedProjectId).dimensionsUsage(),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.project(selectedProjectId).components.base,
        });
      }
      closePreviewModal();
    },
  });

  return { saveItemBatchMutation };
};

export const useItemDropzone = () => {
  const [isItemsDropzoneVisible, setIsItemsDropzoneVisible] = useAtom(
    openItemsImportDropzoneAtom,
  );

  return {
    isItemsDropzoneVisible,
    setIsItemsDropzoneVisible,
  };
};
