import { useEffect, useState } from 'react';
import { Box, Theme, SxProps } from '@mui/material';
import { joinSx } from 'utils/helpers';

type ImageContainerProps = {
  picture?: string | null;
  file?: File | null;
  onImageLoad?: () => void;
  height: number;
  sx?: SxProps<Theme>;
};

type ImageStateType = {
  thumb: string | null;
  aspectRatio?: number;
};

const SUPPORTED_RATIOS = [
  { ratio: 1 }, // 1:1
  { ratio: 5 / 4 }, // 4:5 : 1.25
  { ratio: 4 / 3 }, // 3:4 : 1.33
  { ratio: 9 / 16 }, // 16:9 : 0.5625
  { ratio: 4 / 5 }, // 5:4 : 0.8
];

export const ResponsiveImageContainer = (
  props: ImageContainerProps,
): JSX.Element | null => {
  const [imageState, setImageState] = useState<ImageStateType>({
    thumb: props.picture ?? null,
  });

  const findBestRatio = (naturalWidth: number, naturalHeight: number) => {
    const imageRatio = naturalHeight / naturalWidth;
    return SUPPORTED_RATIOS.reduce((prev, curr) => {
      const prevDiff = Math.abs(prev.ratio - imageRatio);
      const currDiff = Math.abs(curr.ratio - imageRatio);
      return currDiff < prevDiff ? curr : prev;
    });
  };

  useEffect(() => {
    let objectUrl: string | undefined;
    if (props.file) {
      objectUrl = URL.createObjectURL(props.file);
      setImageState({ thumb: objectUrl });
    } else if (!props.picture) {
      setImageState({ thumb: null });
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [props.file, props.picture]);

  const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const img = event.target as HTMLImageElement;
    const bestRatio = findBestRatio(img.naturalWidth, img.naturalHeight);

    props.onImageLoad?.();
    setImageState((prev) => ({
      ...prev,
      aspectRatio: bestRatio.ratio,
    }));
  };

  if (!imageState.thumb) return null;

  return (
    <Box
      sx={joinSx(
        {
          overflow: 'hidden',
          borderRadius: '8px',
        },
        props.sx,
      )}
    >
      <Box
        component="img"
        src={imageState.thumb}
        onLoad={handleImageLoad}
        sx={{
          display: 'block',
          objectFit: 'contain',
          objectPosition: 'center',
          width: '100%',
          maxHeight: `${props.height}px`,
          borderRadius: '8px',
        }}
      />
    </Box>
  );
};
