import { CONCEPT_PROJECT_STATUS } from 'features/ConceptLab/type';
import { ProjectComparison } from 'types/Calibrate';
import { Project, ProjectStatus } from 'types/Project';

export const projectStatusLabels: Record<ProjectStatus | CONCEPT_PROJECT_STATUS, string> =
  {
    ACTIVE: 'In progress',
    DRAFT: 'Draft',
    DRAFT_LEGACY: 'Legacy (Draft)',
    ABANDONED_LEGACY: 'Legacy (Abandoned)',
    COMPLETED: 'Completed',
    ABANDONED: 'Abandoned',
    LEGACY: 'Legacy',
    CONCEPT: 'Concept',
    PLAYGROUND: 'Playground',
  } as const;

export const projectStatusTransition: Record<ProjectStatus, ProjectStatus[]> = {
  DRAFT: ['ACTIVE', 'COMPLETED', 'ABANDONED'],
  ACTIVE: ['COMPLETED', 'ABANDONED'],
  ABANDONED: ['ACTIVE', 'DRAFT'],
  COMPLETED: ['ACTIVE'],
  DRAFT_LEGACY: ['LEGACY'],
  LEGACY: ['DRAFT_LEGACY', 'ABANDONED_LEGACY'],
  ABANDONED_LEGACY: ['LEGACY', 'DRAFT_LEGACY'],
  CONCEPT: ['ACTIVE', 'ABANDONED'],
};

export const getProjectPicture = (
  project: Partial<Pick<Project, 'picture' | 'picture_thumbnail'>>,
) => {
  if (project.picture_thumbnail) {
    return project.picture_thumbnail;
  }

  if (project.picture) {
    return project.picture;
  }

  return null;
};

export const getProjectNormalizationCoefficient = (
  project: ProjectComparison,
  normalizationByCustom: boolean,
  normalizationByLocation: boolean,
  normalizeByTime: boolean,
) => {
  const { by_custom, by_location, by_time } = project.coefficients ?? {};
  const byCustom = normalizationByCustom ? (by_custom ?? 1) : 1;
  const byLocation = normalizationByLocation ? (by_location ?? 1) : 1;
  const byTime = normalizeByTime ? (by_time ?? 1) : 1;

  return byCustom * byLocation * byTime;
};
