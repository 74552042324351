import { useTheme } from '@mui/material';
import { atom, useAtom } from 'jotai';
import { useQueryParam } from './useQueryParam';
import { useAtomWithUserId } from './useAtomWithUserId';
import { atomWithStorage } from 'jotai/utils';
import { useCallback } from 'react';

type SidebarShownState = 'collapsed' | 'expanded';
type SidebarState = 'hidden' | SidebarShownState;

const sidebarShownDefault = 'expanded';
const sidebarStateAtom = atom<SidebarState>('hidden');
const sidebarShownAtom = atomWithStorage<SidebarShownState>(
  'concntric-sidebar-shown',
  sidebarShownDefault,
  undefined,
  { getOnInit: true },
);

export const useSidebarState = () => {
  const [sidebarState, setSidebarState] = useAtom(sidebarStateAtom);
  const [sidebarShown, setSidebarShown] = useAtomWithUserId(
    sidebarShownAtom,
    sidebarShownDefault,
  );

  const showSidebar = useCallback(() => {
    setSidebarState(sidebarShown);
  }, [sidebarShown, setSidebarState]);

  const hideSidebar = useCallback(() => {
    setSidebarState('hidden');
  }, [setSidebarState]);

  const setSidebarStateProxy = useCallback(
    (state: SidebarState) => {
      if (state !== 'hidden') {
        setSidebarShown(state);
      }
      setSidebarState(state);
    },
    [setSidebarShown, setSidebarState],
  );

  return {
    sidebarState,
    setSidebarState: setSidebarStateProxy,
    showSidebar,
    hideSidebar,
  };
};

export const useSidebar = () => {
  const { sidebarState, setSidebarState, showSidebar, hideSidebar } = useSidebarState();
  const theme = useTheme();

  const isSidebarCollapsed = sidebarState === 'collapsed';
  const isSidebarHidden = sidebarState === 'hidden';

  const toggleSidebar = () => {
    if (isSidebarCollapsed) {
      setSidebarState('expanded');
    } else {
      setSidebarState('collapsed');
    }
  };

  const sidebarWidths: Record<SidebarState, number | string> = {
    hidden: 0,
    expanded: theme.sideBarWidth,
    collapsed: theme.sideBarCollapsedWidth,
  };

  const sidebarWidth = sidebarWidths[sidebarState];
  return {
    sidebarState,
    setSidebarState,
    toggleSidebar,
    sidebarWidth,
    isSidebarCollapsed,
    isSidebarHidden,
    showSidebar,
    hideSidebar,
  };
};

export const useShowStepper = () => {
  const query = useQueryParam<{ showStepper?: string }>();
  const showStepperQueryParam = query?.showStepper;
  return showStepperQueryParam === 'true';
};
