import { GenericMapper, TemplateType, estimateTemplateType } from 'types/Estimate';
import winestLogo from 'assets/img/winest-logo.png';
import beckDestiniLogo from 'assets/img/beck-destini-logo.png';
import sageLogo from 'assets/img/sage-logo.png';
import concntricLogo from 'assets/img/concntric-logo-sm-dark.svg';
import costosLogo from 'assets/img/cost-os.png';

export const DATE_FMT = 'MM/DD/YYYY';
export const DATE_FMT_PAYLOAD = 'YYYY-MM-DD';
export const DATE_FMT_PAYLOAD_WITH_TIME = 'YYYY-MM-DDTHH:mm:ss';

export const DATE_FMT_LABEL = 'MMM DD, YYYY';
export const DATE_CURRENT_YEAR_FMT_LABEL = 'MMM DD';
export const DATETIME_FMT_LABEL = 'LLL ';
export const scrollbarWidth = 15;

// Local Storage keys
export const PENDING_PROJECT_REDIRECT = 'project-redirect-in-progress';
export const LAST_VISITED_PAGE = 'last-visited-page';
export const LAST_VISITED_PROJECT_ID = 'last-visited-project-id';

// Naming convention --> https://airbnb.io/javascript/#naming--uppercase
export const TYPOGRAPHY_VARIANTS = {
  fontFamily: 'fontFamily',
  h1: 'h1',
  h1SemiBold: 'h1SemiBold',
  h2: 'h2',
  h2Bold: 'h2Bold',
  h2SemiBold: 'h2SemiBold',
  h3: 'h3',
  h3Medium: 'h3Medium',
  h3SemiBold: 'h3SemiBold',
  h3Bold: 'h3Bold',
  h4: 'h4',
  h4Semibold: 'h4Semibold',
  h4Bold: 'h4Bold',
  h5: 'h5',
  h5SemiBold: 'h5SemiBold',
  h5Bold: 'h5Bold',
  body1: 'body1',
  textDefault: 'textDefault',
  textDefaultMedium: 'textDefaultMedium',
  textDefaultSemiBold: 'textDefaultSemiBold',
  textDefaultBold: 'textDefaultBold',
  textSmall: 'textSmall',
  textSmallBold: 'textSmallBold',
  textSmallSemiBold: 'textSmallSemiBold',
  textExtraSmall: 'textExtraSmall',
  textExtraSmallBold: 'textExtraSmallBold',
  textExtraSmallSemiBold: 'textExtraSmallSemiBold',
  textLarge: 'textLarge',
  textLargeBold: 'textLargeBold',
  textLargeSemiBold: 'textLargeSemiBold',
  textExtraLarge: 'textExtraLarge',
  textExtraLargeBold: 'textExtraLargeBold',
  textExtraLargeSemiBold: 'textExtraLargeSemiBold',
  monospaceDefault: 'monospaceDefault',
} as const;

export const BREAKPOINTS_KEYS = {
  xs: 'xs',
  xsplus: 'xsplus',
  sm: 'sm',
  smplus: 'smplus',
  md: 'md',
  mdplus: 'mdplus',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
};

export const MUI_BUTTON_VARIANTS = {
  contained: 'contained',
  blueGhost: 'blueGhost',
  blueText: 'blueText',
  greyGhost: 'greyGhost',
  greyText: 'greyText',
  redFilled: 'redFilled',
  greenFilled: 'greenFilled',
  yellowFilled: 'yellowFilled',
  yellowGhost: 'yellowGhost',
  activeGreyText: 'activeGreyText',
};

export const MUI_CHIP_VARIANTS = {
  blueGhost: 'blueGhost',
  outlined: 'outlined',
  green: 'green',
  red: 'red',
};

export const genericMapperTemplateTypeMapping: Record<
  GenericMapper['integration'],
  { type: TemplateType; label: string; logo: string }
> = {
  BECK_DESTINI: {
    type: estimateTemplateType.beckDestini,
    label: 'Beck Destini',
    logo: beckDestiniLogo,
  },
  SAGE: { type: estimateTemplateType.sage, label: 'Sage', logo: sageLogo },
  WINEST: { type: estimateTemplateType.winestxls, label: 'Winest', logo: winestLogo },
  COSTOS: { type: estimateTemplateType.costos, label: 'Cost-OS', logo: costosLogo },
  CONCNTRIC: {
    type: estimateTemplateType.concntric,
    label: 'ConCntric',
    logo: concntricLogo,
  },
};

export const ITEM_IMAGES_LIMIT_AMOUNT = 10;

export const MAX_FILENAME_LENGTH = 1024;

export const FORESITE_ITEMS_PER_PAGE = 20;

// Regex to validate urls
export const WEB_URL_REGULAR_EXPRESSION =
  // eslint-disable-next-line no-useless-escape
  /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?[a-zA-Z0-9]+(?:[\-\.][a-zA-Z0-9]+)*(?:\.[a-zA-Z]{2,}|\.[a-zA-Z]{2,5})(?:\/[^\s/]+)*\/?$/;

// Regex to validate URL Domains: https://regexr.com/3au3g
export const WEB_URL_DOMAIN_REGULAR_EXPRESSION =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;

export const urlQueryParams = {
  itemDetailsId: 'itemDetailsId',
  focusSection: 'focusSection',
};

export const FULL_PERCENTAGE_DECIMALS = 5;
