import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import usePermissions from 'features/Auth/hook/usePermissions';
import { isEmpty } from 'lodash-es';
import { isLegacyOrDraftProject } from 'mappings/project';
import { routePermissions } from 'routePermissions';
import { PrivateURL } from 'Urls';
import { queryKeys } from 'utils/reactQuery';
import { MissingStep } from '../types';
import { useProject } from './useProject';
import { useSelectedProjectId } from './useSelectedProjectId';
import { PermissionType } from 'features/Auth/Permissions';
import { Project } from 'types/Project';

const optionalSteps = ['TIMELINE', 'COMPONENTS', 'ESTIMATE'] as const;

export type OptionalProjectData = (typeof optionalSteps)[number][0];

type MissingData = {
  has_components: boolean;
  has_estimate: boolean;
  has_milestones: boolean;
};

const getPredicates = (
  missingDataInfo: MissingData | undefined,
  project: Project | undefined,
  isProjectStatusLegacy: boolean,
) => ({
  TIMELINE: () => !missingDataInfo?.has_milestones,
  COMPONENTS: () => !missingDataInfo?.has_components && !isProjectStatusLegacy,
  ESTIMATE: () => !project || !missingDataInfo?.has_estimate,
});

const getMissingData = (
  predicates: Record<OptionalProjectData, () => boolean>,
  hasPerm: (permission: PermissionType) => boolean,
) =>
  optionalSteps
    .filter((step) => predicates[step]())
    .map((step) => {
      const path = PrivateURL[step];
      const permission = routePermissions[path];
      return {
        step,
        hasPermission: hasPerm(permission),
      };
    });

export const useProjectMissingData = (): {
  isMissingDataLoading: boolean;
  hasMissingData: boolean;
  missingData: MissingStep[];
} => {
  const { project, projectQuery } = useProject();
  const {
    missingDataQuery: { data: missingDataInfo, isLoading: isDataLoading },
  } = useMissingData();

  const isProjectStatusLegacy = isLegacyOrDraftProject(project);

  const { hasPerm } = usePermissions();

  const isMissingDataLoading = projectQuery.isLoading || isDataLoading;

  const predicates = getPredicates(missingDataInfo, project, isProjectStatusLegacy);

  const missingData = getMissingData(predicates, hasPerm);

  return {
    isMissingDataLoading,
    hasMissingData: !isEmpty(missingData),
    missingData,
  };
};

const useMissingData = () => {
  const { selectedProjectId } = useSelectedProjectId();
  const missingDataQuery = useQuery({
    queryKey: queryKeys.project(selectedProjectId).missingData,
    queryFn: ({ signal }) => {
      const endPoint = Resources.PROJECT_MISSING_DATA_INFO.replace(
        '<int:project_pk>',
        (selectedProjectId ?? '') as string,
      );
      return ApiService.get(endPoint, { signal }).then((res) => res.data as MissingData);
    },
    staleTime: Infinity,
    retryOnMount: false,
    enabled: !!selectedProjectId,
  });

  return { missingDataQuery };
};

export const getProjectMissingData = async (
  project: Project,
  hasPerm: (permission: PermissionType) => boolean,
) => {
  const missingDataInfo = await ApiService.get(
    Resources.PROJECT_MISSING_DATA_INFO.replace('<int:project_pk>', String(project.id)),
  ).then((res) => res.data as MissingData);

  const predicates = getPredicates(
    missingDataInfo,
    project,
    isLegacyOrDraftProject(project),
  );

  return getMissingData(predicates, hasPerm);
};
