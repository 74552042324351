import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';

export const EmailSupportIconButton = () => (
  <IconButton
    onClick={() => (window.location.href = 'mailto:support@concntric.com')}
    sx={{
      position: 'fixed',
      bottom: 20,
      right: 20,
      zIndex: Number.MAX_SAFE_INTEGER,
      width: 48,
      height: 48,
      bgcolor: 'primary.main',
      color: 'white',
      '&:hover': {
        bgcolor: 'primary.dark',
      },
    }}
  >
    <FontAwesomeIcon icon={faEnvelope} />
  </IconButton>
);
