import { faUserShield } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { SideBarListItem } from './SideBarListItem';
import { RoleImpersonationFlyout } from 'features/Projects/components/RoleImpersonationFlyout';

export const RoleImpersonationLinkItemAndModal = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleListItemClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFlyoutClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SideBarListItem
        icon={faUserShield}
        label="Role Impersonation"
        onClick={handleListItemClick}
      />

      <RoleImpersonationFlyout
        open={Boolean(anchorEl)}
        onClose={handleFlyoutClose}
        width="smplus"
        title="Role Impersonation"
        data-testid="role-impersonation-flyout-header"
      />
    </>
  );
};
