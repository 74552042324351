import { PrivateURL } from 'Urls';
import { PermissionType, Permissions } from 'features/Auth/Permissions';

export const routePermissions: Record<string, PermissionType> = {
  [PrivateURL.TIMELINE]: Permissions.TIMELINE_ACCESS, // 'Timeline'
  [PrivateURL.COMPONENTS]: Permissions.COMPONENT_ACCESS, // 'Components'
  [PrivateURL.TVD]: Permissions.TVD_ACCESS, // 'Target Value Design'
  [PrivateURL.OVERVIEW]: Permissions.OVERVIEW_ACCESS, // 'Overview'
  [PrivateURL.VISION]: Permissions.VISION_ACCESS, // 'Vision'
  [PrivateURL.FORESITE]: Permissions.FORESITE_ACCESS, // 'ForeSite'
  [PrivateURL.ESTIMATE]: Permissions.ESTIMATE_ACCESS, // 'Estimate'
  [PrivateURL.ESTIMATE_IMPORT]: Permissions.ESTIMATE_ACCESS, // 'Estimate'
  [PrivateURL.REPORTS]: Permissions.REPORTING_ACCESS, // 'Reporting'
  [PrivateURL.CONTEXT]: Permissions.CONTEXT_ACCESS, // 'Context Page'
  [PrivateURL.ISSUE_MANAGEMENT]: Permissions.ISSUE_ACCESS, // 'Issue Management'
  [PrivateURL.INVITE_MEMBERS]: Permissions.INVITE_MEMBERS_TO_PROJECT, // 'Manage members'
  [PrivateURL.CALIBRATE_DIVISION]: Permissions.CALIBRATE_ACCESS, // 'Calibrate Compare by division'
  [PrivateURL.CALIBRATE]: Permissions.CALIBRATE_ACCESS, // 'Calibrate'
  [PrivateURL.AREA_SETUP]: Permissions.PROGRAM_ACCESS, // 'Program'
  [PrivateURL.RISKS]: Permissions.RISK_ACCESS, // 'Risk Register'
  [PrivateURL.PROCUREMENT_WIZARD_BID_PACKAGES_SETUP]: Permissions.PROCUREMENT_ACCESS, // 'Procurement Wizard: Bid Packages Setup'
  [PrivateURL.PROCUREMENT_WIZARD_PURCHASE_PLAN]: Permissions.PROCUREMENT_ACCESS, // 'Procurement Wizard: Purchase plan'
  [PrivateURL.PROCUREMENT_WIZARD_DOCUMENTATION]: Permissions.PROCUREMENT_ACCESS, // 'Procurement Wizard: Documentation'
  [PrivateURL.PROCUREMENT_WIZARD_BID_PACKAGE_DEVELOPMENT]: Permissions.PROCUREMENT_ACCESS, // 'Procurement Wizard: Bid Package Development'
  [PrivateURL.PROCUREMENT_BID_PACKAGE_DETAILS]: Permissions.PROCUREMENT_ACCESS, // 'Procurement Bid Package Details'
  [PrivateURL.PROCUREMENT]: Permissions.PROCUREMENT_ACCESS, // 'Procurement'
  [PrivateURL.MANAGE_USERS]: Permissions.ACCOUNT_ADMINISTRATION_ACCESS, // 'Account administration'
  [PrivateURL.NORMALIZATION_INDICES]: Permissions.NORMALIZATION_INDICES_ACCESS, // 'Normalization Indices'
  [PrivateURL.PROJECT_SETUP]: Permissions.ADD_PROJECT, // 'Blank Project - Setup'
  [PrivateURL.PROJECT_NEW_OPTIONS]: Permissions.ADD_PROJECT, // 'New Project - Options'
  [PrivateURL.PROJECT_LEGACY_SETUP]: Permissions.ADD_PROJECT, // 'Legacy Project - Setup'
  [PrivateURL.PROJECT_LEGACY_COMPONENTS]: Permissions.COMPONENT_ACCESS, // 'Legacy Project - Components setup'
  [PrivateURL.PROJECT_LEGACY_ESTIMATE]: Permissions.ESTIMATE_ACCESS, // 'Legacy Project - Estimate upload'
  [PrivateURL.PROJECT_LEGACY_UPDATE]: Permissions.CHANGE_PROJECT, // 'Legacy Project - Update'
  [PrivateURL.PROJECT_DASHBOARD_PORTFOLIO]: Permissions.PORTFOLIO_ACCESS, // 'Portfolio'
  [PrivateURL.PORTFOLIO]: Permissions.PORTFOLIO_ACCESS, // 'Portfolio'
  [PrivateURL.FORESITE_LIBRARY]: Permissions.FORESITE_ACCESS, // 'ForeSite Library'
  [PrivateURL.CUSTOM_CODES]: Permissions.CUSTOM_CODES_ACCESS, // 'Custom Formats'
  [PrivateURL.PUBLIC_API_DOCUMENTATION]: Permissions.VIEW_APIKEY, // 'Public API Documentation'
  [PrivateURL.PUBLIC_API]: Permissions.VIEW_APIKEY, // 'Public API'
  // [PrivateURL.PROJECT_UPDATE]: // 'Project Details', Always visible
  [PrivateURL.MARKUP_TEMPLATES]: Permissions.MARKUP_TEMPLATES_ACCESS, // 'Markup Templates'
  [PrivateURL.CONCEPT_LAB]: Permissions.CONCEPT_LAB_ACCESS, // 'Concept Lab'
  [PrivateURL.CONCEPT_LAB_ANALYSIS_BY_ID]: Permissions.CONCEPT_LAB_ACCESS, // 'Concept Lab Analysis Details'
  [PrivateURL.CONCEPT_LAB_CONCEPTS]: Permissions.CONCEPT_LAB_ACCESS, // 'Concept Lab Analysis Concept view'
  [PrivateURL.CONCEPT_HUB_SCENARIO_BY_ID]: Permissions.CONCEPT_LAB_ACCESS, // 'Concept Lab Analysis Concept view'
  [PrivateURL.CONCEPT_HUB_SCENARIOS]: Permissions.CONCEPT_LAB_ACCESS, // 'Concept Lab Analysis Promote'
  [PrivateURL.CUSTOM_ATTRIBUTES]: Permissions.CUSTOM_ATTRIBUTES_ACCESS, // 'Custom attributes'
} as const;
