import log from 'loglevel';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'utils/reactQuery';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { TenantFeatureFlags, tenantFeatureFlagsKeys } from 'types/Common';
import { isEmpty } from 'lodash-es';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';
import { AuthenticationService } from 'api/AuthenticationService';

const placeholderData = tenantFeatureFlagsKeys.reduce(
  (flags, current) => ({ ...flags, [current]: false }),
  {},
) as TenantFeatureFlags;

export const useTenantFeatureFlags = () => {
  const { currentUser } = useCurrentUser();
  const authService = AuthenticationService.getInstance();

  const enabled = Boolean(currentUser) && Boolean(authService.isAuthenticated);

  const tenantFeatureFlagsQuery = useQuery({
    queryKey: queryKeys.tenantFeatureFlags,
    queryFn: ({ signal }) => {
      return ApiService.get(Resources.FEATURE_FLAGS, { signal }).then(
        (res) => (res.data ?? {}) as TenantFeatureFlags,
      );
    },
    staleTime: Infinity,
    enabled,
  });
  if (
    enabled &&
    isEmpty(tenantFeatureFlagsQuery.data) &&
    !tenantFeatureFlagsQuery.isLoading
  ) {
    // CPE-5168 hotfix
    const errorMessage =
      'Illegal state: empty tenant feature flags data. Using defaults.';
    log.error(errorMessage);
  }

  const { data, ...rest } = tenantFeatureFlagsQuery;

  return {
    tenantFeatureFlags: data ?? placeholderData,
    ...rest,
  };
};
