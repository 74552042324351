import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { useSetting } from 'hooks/useSetting';
import { CustomizeNotificationsSettings, ProjectSettings } from 'types/Setting';
import { ALL_NOTIFICATIONS_ENABLED_CONFIG } from '../utils';

const openSettingsFlyoutAtom = atom(false);

export const useSettingsFlyout = () => {
  const [isSettingsFlyoutOpen, setIsSettingsFlyoutOpen] = useAtom(openSettingsFlyoutAtom);

  const openSettingsFlyout = useCallback(() => {
    setIsSettingsFlyoutOpen(true);
  }, [setIsSettingsFlyoutOpen]);

  const closeSettingsFlyout = useCallback(() => {
    setIsSettingsFlyoutOpen(false);
  }, [setIsSettingsFlyoutOpen]);

  return {
    isSettingsFlyoutOpen,
    openSettingsFlyout,
    closeSettingsFlyout,
  };
};

export function useCustomizeNotificationsSettings() {
  return useSetting<CustomizeNotificationsSettings>(
    'customize-notifications',
    ALL_NOTIFICATIONS_ENABLED_CONFIG,
  );
}

export function useProjectSettings() {
  return useSetting<ProjectSettings>('project-settings', {
    visibility: {
      showCostGsf: true,
      showOwnerCost: false,
    },
    measurement_preferences: {
      area: '',
    },
  });
}
