export const ALL_NOTIFICATIONS_ENABLED_CONFIG = {
  DUE_DATES: {
    enabled: true,
  },
  MENTIONS: {
    enabled: true,
  },
  ITEMS_STATUS_CHANGE: {
    enabled: true,
  },
  SCENARIOS_STATUS_CHANGE: {
    enabled: true,
  },
  ISSUES_STATUS_CHANGE: {
    enabled: true,
  },
};

export const NOTIFICATIONS = [
  { code: 'DUE_DATES', label: 'Due dates', info: 'Due dates approaching on an item' },
  {
    code: 'ISSUES_STATUS_CHANGE',
    label: 'Issues status change',
    info: 'Change on issue status or a new assignee added',
  },
  {
    code: 'ITEMS_STATUS_CHANGE',
    label: 'Items status changes',
    info: 'Change on a item status',
  },
  { code: 'MENTIONS', label: 'Mentions', info: 'Mentions inside of comments' },
  {
    code: 'SCENARIOS_STATUS_CHANGE',
    label: 'Scenarios status',
    info: 'Change on a scenario status',
  },
] as const;
