import React, { useState } from 'react';
import { Select, Button, SelectChangeEvent, Box, MenuItem } from '@mui/material';
import { faUserGroupSimple } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  usePermissionRoles,
  useRoleImpersonation,
  useRoleStopImpersonation,
} from 'features/ManageUsers/hooks/permissionRoles';
import { useCurrentUser } from 'features/Auth/hook/useCurrentUser';

export const RoleImpersonationToolbar: React.FC = () => {
  const [selectedRole, setSelectedRole] = useState<string>('');
  const {
    permissionRolesQuery: { data: roles },
  } = usePermissionRoles();

  const {
    permissionRoleImpersonationMutation: { mutate: impersonateRole },
  } = useRoleImpersonation();
  const {
    permissionRoleImpersonationMutation: { mutate: stopImpersonation },
  } = useRoleStopImpersonation();

  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    const roleId = Number(event.target.value);
    const projectId = Number(window.location.pathname.split('/')[2]);
    setSelectedRole(event.target.value);
    if (roleId) {
      impersonateRole(
        { roleId, projectId },
        {
          onSuccess: () => {
            window.location.reload();
          },
        },
      );
    }
  };

  const handleStopImpersonation = () => {
    stopImpersonation(undefined, {
      onSuccess: () => {
        setSelectedRole('');
        window.location.reload();
      },
    });
  };
  // Filter only impersonable roles
  const impersonableRoles = roles?.filter((role) => role.is_impersonable) || [];
  const { currentUser } = useCurrentUser();
  const selectedRoleId =
    selectedRole ||
    currentUser?.role_impersonation?.impersonated_role?.id.toString() ||
    '';
  return (
    currentUser?.role_impersonation?.is_impersonating && (
      <Box
        sx={{
          position: 'fixed',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1200,
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          padding: '8px 16px',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          maxWidth: '90vw', // Prevent overflow on small screens
          width: 'auto',
        }}
      >
        <Box
          component={FontAwesomeIcon}
          icon={faUserGroupSimple}
          sx={{ color: 'grey.500' }}
        />
        <Box component="span" sx={{ color: 'grey.500' }}>
          You are impersonating as
        </Box>
        <Select
          value={selectedRoleId}
          onChange={handleRoleChange}
          displayEmpty
          sx={{
            minWidth: 200,
            '& .MuiSelect-select': { py: 1 },
          }}
        >
          {impersonableRoles.map((role: { id: number; name: string }) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          color="primary"
          onClick={handleStopImpersonation}
          disabled={!selectedRoleId}
        >
          Stop impersonation
        </Button>
      </Box>
    )
  );
};
