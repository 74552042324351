import usePermissions from 'features/Auth/hook/usePermissions';
import { PermissionType } from 'features/Auth/Permissions';
import { useProject } from 'features/Projects/hook/useProject';
import { useProjectMissingData } from 'features/Projects/hook/useProjectMissingData';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { projectSections, Section } from 'Urls';
import { useTenantFeatureFlags } from 'hooks/useTenantFeatureFlags';
import { MissingStep } from 'features/Projects/types';
import { routePermissions } from 'routePermissions';

type Route = Section & {
  isDataMissing?: boolean;
};

const createFilterSectionByItsRoutePermission =
  (hasPerm: (permission: PermissionType) => boolean) => (route: Route | null | false) => {
    if (!route) return false;
    const permission = routePermissions[route.url];
    return !permission || hasPerm(permission);
  };

type SectionRoute = {
  sectionLabel: string;
  routes: Route[];
};

function getNewProjectRoutes({
  hasPerm,
  missingData,
  isProcurementEnabled,
  isReportingEnabled,
  isContextEnabled,
  isIssueManagementEnabled,
}: {
  hasPerm: (permission: PermissionType) => boolean;
  missingData: MissingStep[];
  isProcurementEnabled: boolean;
  isReportingEnabled: boolean;
  isContextEnabled: boolean;
  isIssueManagementEnabled: boolean;
}) {
  return [
    {
      sectionLabel: 'SOLUTIONS',
      routes: [
        projectSections.vision,
        projectSections.foresite,
        projectSections.tvd,
        projectSections.calibrate,
        isProcurementEnabled ? projectSections.procurement : null,
        projectSections.risks,
        isReportingEnabled ? projectSections.reports : null,
        isContextEnabled && projectSections.context,
        isIssueManagementEnabled && projectSections.issueManagement,
        {
          ...projectSections.timeline,
          isDataMissing: missingData.some(({ step }) => step === 'TIMELINE'),
        },
      ].filter(createFilterSectionByItsRoutePermission(hasPerm)),
    },
    {
      sectionLabel: 'MANAGE PROJECT',
      routes: [
        projectSections.details,

        {
          ...projectSections.components,
          isDataMissing: missingData.some(({ step }) => step === 'COMPONENTS'),
        },

        {
          ...projectSections.estimateUpdate,
          isDataMissing: missingData.some(({ step }) => step === 'ESTIMATE'),
        },
        projectSections.area,
      ].filter(createFilterSectionByItsRoutePermission(hasPerm)),
    },
  ] as SectionRoute[];
}

function getLegacyProjectRoutes({
  hasPerm,
  isReportingEnabled,
}: {
  hasPerm: (permission: PermissionType) => boolean;
  isReportingEnabled: boolean;
}) {
  return [
    {
      sectionLabel: 'Overview',
      routes: [
        projectSections.calibrate,
        isReportingEnabled && projectSections.reports,
      ].filter(createFilterSectionByItsRoutePermission(hasPerm)),
    },
    {
      sectionLabel: 'Manage project',
      routes: [
        projectSections.details,
        projectSections.components,
        projectSections.estimateUpdate,
      ].filter(createFilterSectionByItsRoutePermission(hasPerm)),
    },
  ] as SectionRoute[];
}

export function useSectionRoutes() {
  const { project } = useProject();
  const { hasPerm } = usePermissions();
  const { isMissingDataLoading, missingData } = useProjectMissingData();
  const { isReportingEnabled, isIssueManagementEnabled } =
    useFeatureFlags();
  const { tenantFeatureFlags } = useTenantFeatureFlags();
  if (!project || project.status === 'DRAFT_LEGACY') return [];

  if (project.status === 'LEGACY') {
    return getLegacyProjectRoutes({ hasPerm, isReportingEnabled });
  } else {
    return getNewProjectRoutes({
      hasPerm,
      missingData: isMissingDataLoading ? [] : missingData,
      isProcurementEnabled: !!tenantFeatureFlags.procurement_available,
      isReportingEnabled,
      isContextEnabled: !!tenantFeatureFlags.context_module,
      isIssueManagementEnabled,
    });
  }
}
