import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { AreaValue } from 'components/AreaInput/type';
import { useTenantFeatureFlags } from 'hooks/useTenantFeatureFlags';
import { useMemo } from 'react';
import { EntityCustomAttributeValue } from 'types/CustomAttributes';
import { Project } from 'types/Project';
import { addSearchParamsToUrl } from 'utils/helpers';
import { queryKeys } from 'utils/reactQuery';

export function useProjectById({
  id,
  enabled = true,
}: {
  id?: number;
  enabled?: boolean;
}) {
  const {
    tenantFeatureFlags: { enable_custom_attributes: isCustomAttributesEnabled },
    isSuccess: isTenantFeatureFlagsSuccess,
  } = useTenantFeatureFlags();

  const projectQuery = useQuery({
    queryKey: queryKeys.project(id).details,
    queryFn: ({ signal }) => {
      const endPoint = Resources.PROJECT_BY_ID.replace('<int:pk>', `${id}`);
      const url = addSearchParamsToUrl({
        url: endPoint,
        searchParams: { expand_custom_attributes: isCustomAttributesEnabled },
      });
      return ApiService.get(url, { signal }).then((res) => res.data as Project);
    },
    staleTime: Infinity,
    retryOnMount: false,
    enabled: Boolean(enabled && !!id && isTenantFeatureFlagsSuccess),
  });

  const data = useMemo(() => {
    if (!projectQuery.data) return projectQuery.data;
    const project = { ...projectQuery.data };
    let customAttributes: EntityCustomAttributeValue[] = [];
    if (project.custom_attributes && typeof project.custom_attributes === 'string') {
      try {
        customAttributes = JSON.parse(
          project.custom_attributes,
        ) as EntityCustomAttributeValue[];
      } catch (error) {}
    }
    project.custom_attributes = customAttributes;

    let targetArea = {
      unit: 'sq_ft',
      value: 0,
    } as AreaValue;
    try {
      targetArea = JSON.parse(project.target_area as unknown as string) as AreaValue;
    } catch (error) {}
    project.target_area = targetArea;

    return project as Project;
  }, [projectQuery.data]);

  return { projectQuery: { ...projectQuery, data }, project: data };
}
