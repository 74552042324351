import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

type ImageContainerProps = {
  picture?: string | null;
  file?: File | null;
  onImageLoad?: () => void;
};

type ImageStateType = { thumb: string | null };
export const ImageContainer = (props: ImageContainerProps): JSX.Element | null => {
  const [imageState, setImageState] = useState<ImageStateType>({
    thumb: props.picture ?? null,
  });

  useEffect(() => {
    let objectUrl: string | undefined;
    if (props.file) {
      objectUrl = URL.createObjectURL(props.file);
      setImageState({ thumb: objectUrl });
    } else if (!props.picture) {
      setImageState({ thumb: null });
    }
    // free memory whenever this component is unmounted
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [props.file]);

  return imageState.thumb ? (
    <>
      <Box
        component="img"
        src={imageState.thumb}
        onLoad={() => {
          props.onImageLoad?.();
          setImageState({ thumb: imageState.thumb });
        }}
        sx={{
          objectFit: 'cover',
          objectPosition: 'center',
          overflow: 'hidden',
          height: '100%',
          width: '100%',
          borderRadius: '8px',
        }}
      />
    </>
  ) : null;
};
