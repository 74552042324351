import newLogo from 'assets/img/concntric-logo-light.svg';
import { Box } from '@mui/system';
import { useCurrentUserCompanyLogo } from 'features/Company/hooks/company';

export const CompanyLogo = () => {
  const {
    companyLogoQuery: { data: picture },
  } = useCurrentUserCompanyLogo();

  return (
    <Box
      sx={{
        height: '3rem',
        width: '10rem',
        mr: 2,
        backgroundImage: `url(${picture ? picture.data : newLogo})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};
