import { Item } from 'types/Item';
import { DesignMilestone } from 'types/DesignMilestones';

type SelectableCondition = {
  isFulfill: boolean;
  description: string;
};
export const getItemSelectableConditions = ({
  item,
}: {
  item: Item | undefined;
}): SelectableCondition[] => [
  {
    isFulfill: item?.status === 'PENDING',
    description: 'Item should have "Pending" status',
  },
  {
    isFulfill: !!item?.has_valid_scope,
    description: 'Item should have valid Scope(s)',
  },
];

export const getItemForesiteSelectableConditions = ({
  item,
  scenariosFocus,
  milestones,
  selectedItems = [],
}: {
  item: Item | undefined;
  scenariosFocus: number[];
  milestones: DesignMilestone[] | undefined;
  selectedItems?: Item[];
}): SelectableCondition[] => [
  ...getItemSelectableConditions({ item }),
  {
    isFulfill: scenariosFocus.length === 0,
    description: "There shouldn't be any Scenario selected",
  },
  {
    isFulfill: Boolean(milestones?.length),
    description: 'You need to set at least one milestone',
  },
  {
    isFulfill: !selectedItems
      .filter((selectedItem) => selectedItem.id !== item?.id)
      .some((selectedItem) =>
        selectedItem.me_groups?.some((meGroup) => item?.me_groups?.includes(meGroup)),
      ),
    description: 'Cannot select mutually exclusive items together',
  },
];
