import { Role, UserBrief } from 'types/User';
import { GridColumns } from 'types/helpers';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { faUserShield } from '@fortawesome/pro-regular-svg-icons';
import { Box, IconButton, Typography } from '@mui/material';
import { MemberChip } from 'components/CustomChips/MemberChip';
import { getUserFullnameOrEmail } from 'utils/helpers';
import { OverlapWithBadgeWrapper } from 'components/OverlapWithBadgeWrapper/OverlapWithBadgeWrapper';
import {
  usePermissionRoles,
  useRoleImpersonation,
} from 'features/ManageUsers/hooks/permissionRoles';
import { NONE_OUTLINED_SELECTED_CELL, STRIPPED_GRID } from 'style/DataGridCommonStyles';
import { useRef } from 'react';
import { useRedirectHome } from 'hooks/navigate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const cellsThatOpenItemModal = [
  'id',
  'name',
  'projects',
  'created_at',
  'creator',
  'status',
];

export const RoleImpersonationTable = () => {
  const { permissionRolesQuery } = usePermissionRoles();
  const { permissionRoleImpersonationMutation } = useRoleImpersonation();
  const actionsMenuRef = useRef<{ closeMenu: () => void }>();
  const { redirectHome } = useRedirectHome();

  const handleRoleImpersonation = async (roleId: number) => {
    const currentProjectId = window.location.pathname.split('/')[2]; // Assuming URL pattern is /projects/{projectId}/...
    try {
      await permissionRoleImpersonationMutation.mutateAsync({
        roleId,
        projectId: parseInt(currentProjectId),
      });
      // Only proceed with redirect and reload after successful mutation
      redirectHome?.();
      window.location.reload();
      actionsMenuRef.current?.closeMenu();
    } catch (error) {
      console.error('Role impersonation failed:', error);
    }
  };

  const isBusy =
    permissionRolesQuery.isLoading || permissionRoleImpersonationMutation.isPending;

  const columns: GridColumns<Role, '_availability' | 'actions'> = [
    {
      field: 'name',
      headerName: 'Project Roles',
      flex: 1,
      renderCell: (params) => (
        <Typography variant="textDefaultSemiBold">{params.value}</Typography>
      ),
    },
    {
      field: 'members',
      headerName: 'Members',
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <OverlapWithBadgeWrapper
            labels={params.value.map(getUserFullnameOrEmail)}
            badgeTop={16}
          >
            {params.value.map((member: UserBrief) => (
              <MemberChip key={member.id} member={member} />
            ))}
          </OverlapWithBadgeWrapper>
        </Box>
      ),
    },
    {
      field: 'actions',
      sortable: false,
      headerName: 'Impersonate',
      width: 150,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <IconButton
              onClick={() => handleRoleImpersonation(params.row.id)}
              sx={{
                color: 'primary.main',
                '&:hover': {
                  color: 'primary.dark',
                },
                padding: '8px',
                minWidth: 'auto',
                borderRadius: '8px',
              }}
            >
              <FontAwesomeIcon icon={faUserShield} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {permissionRolesQuery.data?.filter((role) => role.is_impersonable)?.length ? (
        <DataGridPremium
          loading={isBusy}
          rows={
            permissionRolesQuery.data
              ?.filter((role) => role.is_impersonable)
              ?.toSorted((a, d) => a.id - d.id) ?? []
          }
          columns={columns}
          autoHeight
          rowHeight={72}
          hideFooter
          disableColumnMenu
          disableColumnReorder
          disableRowSelectionOnClick
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          sx={[
            {
              '& .MuiDataGrid-cell': {
                [`${cellsThatOpenItemModal.map((c) => `&[data-field="${c}"]`).join(', ')}`]:
                  {
                    cursor: 'pointer',
                  },
              },
            },
            NONE_OUTLINED_SELECTED_CELL,
            STRIPPED_GRID,
          ]}
        />
      ) : (
        <Typography variant="body1" sx={{ textAlign: 'center', py: 4 }}>
          There are no roles to be impersonated
        </Typography>
      )}
    </>
  );
};
