

import { Box } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { joinSx } from 'utils/helpers';

export function NavBarPopoverButton({
    onClick,
    to,
    sx,
    children,
  }: {
    onClick: () => void;
    to?: string;
    sx?: SxProps;
    children: ReactNode;
  }) {
    return (
      <Box
        {...(to
          ? {
              component: Link,
              to,
            }
          : {})}
        sx={joinSx(
          {
            cursor: 'pointer',
            px: 1.5,
            py: 1,
            display: 'flex',
            gap: 1,
            '&:hover': { backgroundColor: 'blue.50' },
          },
          sx,
        )}
        onClick={onClick}
      >
        {children}
      </Box>
    );
  }
  