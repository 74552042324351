import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Theme,
  SxProps,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Checkbox,
  Select,
  Chip,
  Typography,
  TextField,
  ListItemText,
  Box,
  MenuProps,
  Tooltip,
} from '@mui/material';
import { entries, isEqual, keyBy, orderBy } from 'lodash-es';
import {
  isValidElement,
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { joinSx } from 'utils/helpers';
import { SearchForTextField } from 'components/Select/SearchForTextField';
import { Option } from 'components/MultiSelect/MultiSelect.type';

const MAX_INPUT_WIDTH = '11.5rem';
const MIN_INPUT_WIDTH = '7rem';

type MultiSelectProps<T> = {
  loading?: boolean;
  title: ReactNode;
  prependTitleOnSelect?: boolean;
  inputPropsSx?: SxProps<Theme>;
  options: Option<T>[];
  value: T[];
  onChange: (newValue: T[]) => void;
  enableSearch?: boolean;
  sx?: SxProps<Theme>;
  MenuProps?: Partial<MenuProps>;
  bottom?: ReactNode;
  renderOption?: (option: Option<T>) => ReactNode;
  enableCreate?: boolean;
  enableSelectAll?: boolean;
  enableClearSelection?: boolean;
  onCreateItem?: (name: string) => Promise<T>;
  disabled?: boolean;
  renderOnlyValueCount?: boolean;
  noSelectionMessage?: string;
  defaultOpen?: boolean;
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  groupOptions?: boolean;
};

// Add a type for group headers
type GroupHeader<T> = Option<T> & {
  isGroupHeader: boolean;
};

export const MultiSelect = <T extends number | string>(props: MultiSelectProps<T>) => {
  const [searchString, setSearchString] = useState('');
  const searchRef = useRef<HTMLDivElement | null>(null);
  const { onChange, options: allOptions, value } = props;

  // We need to convert option's values to options and vice versa as Select component
  // options should be primitive values
  const optionsMap = keyBy(props.options, 'value');

  const options = useMemo(() => {
    const options =
      props.enableSearch && !!searchString
        ? props.options.filter((option) =>
            option.searchableLabel?.toLowerCase().includes(searchString.toLowerCase()),
          )
        : props.options;

    return orderBy(options, (o) => !o.isUntagged);
  }, [props.options, props.enableSearch, searchString]);

  const groupedOptions = useMemo(() => {
    if (!props.groupOptions) return options;

    const groups = options.reduce(
      (acc, option) => {
        const groupKey = option.group ?? '';
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(option);
        return acc;
      },
      {} as Record<string, typeof options>,
    );

    return entries(groups).flatMap(([group, groupOptions]) => [
      // Add proper typing for group header
      {
        isGroupHeader: true,
        label: group,
        value: `group-header-${group}`, // Add unique value for each group header
      } as GroupHeader<T>,
      ...groupOptions,
    ]);
  }, [options, props.groupOptions]);

  useEffect(() => {
    // Clear values no longer present in the available options
    if (value.some((value) => !allOptions.find((o) => o.value === value))) {
      onChange(value.filter((value) => allOptions.find((o) => o.value === value)));
    }
  }, [allOptions, onChange, value]);

  const exactSearchOption = props.enableCreate
    ? options.find((option) => {
        const label = option.searchableLabel ?? (option.label as string);
        return label.trim().toLowerCase() === searchString.trim().toLowerCase();
      })
    : null;

  const handleAddOption = (addedValue: T) => {
    props.onChange([...props.value, addedValue]);
    setSearchString('');
    setTimeout(() => {
      searchRef.current?.focus();
    }, 0);
  };

  const handleAddSearchOption = async () => {
    if (searchString) {
      const addedTagValue = (await props.onCreateItem?.(searchString)) as T;
      setTimeout(() => {
        handleAddOption(addedTagValue);
      }, 0);
    }
  };

  const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' && props.enableCreate) {
      if (exactSearchOption) {
        handleAddOption(exactSearchOption.value);
      } else {
        handleAddSearchOption();
      }
    }
  };
  const isAllSelected = useMemo(
    () =>
      isEqual(
        props.value,
        options.map((o) => o.value),
      ),
    [props.value, options],
  );
  const firstTaggedOptionIndex = options.findIndex((o) => !o.isUntagged);

  return props.loading ? (
    <TextField
      disabled
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CircularProgress size={22} />
          </InputAdornment>
        ),
        sx: joinSx(
          { maxWidth: MAX_INPUT_WIDTH, minWidth: MIN_INPUT_WIDTH },
          props.inputPropsSx,
        ),
      }}
    />
  ) : (
    <Select
      multiple
      displayEmpty
      value={props.value.filter((v) => !v.toString().startsWith('group-header-'))}
      defaultOpen={props.defaultOpen}
      renderValue={(selected) => {
        const option = selected.at(0) && optionsMap[selected.at(0) as T];
        const optionLabel =
          !props.renderOnlyValueCount && selected.length > 0 ? (
            <Typography
              component="span"
              variant="textDefaultSemiBold"
              sx={{
                color: 'grey.700',
                ml: 0.5,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                minWidht: 0,
              }}
            >
              {option?.selectedLabel ?? option?.label}
            </Typography>
          ) : selected.length === 0 && props.noSelectionMessage ? (
            <Typography
              component="span"
              variant="textDefaultSemiBold"
              sx={{
                color: 'grey.700',
                ml: 0.5,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                minWidht: 0,
              }}
            >
              {props.noSelectionMessage}
            </Typography>
          ) : null;
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
              width: '100%',
            }}
          >
            {props.prependTitleOnSelect ? (
              <Typography
                component="span"
                variant="textDefaultSemiBold"
                sx={{
                  color:
                    selected.length > 0 || props.noSelectionMessage
                      ? 'grey.400'
                      : 'inherit',
                  mr: 0.5,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {props.title}
                {selected.length > 0 || props.noSelectionMessage ? ':' : ''}
                {optionLabel}
              </Typography>
            ) : selected.length === 0 && props.title ? (
              <Typography
                component="span"
                sx={{
                  color: 'grey.400',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                className="title-label"
              >
                {props.title}
              </Typography>
            ) : (
              optionLabel
            )}
            {props.renderOnlyValueCount ? (
              selected.length > 0 && (
                <Chip
                  label={`${selected.length}`}
                  sx={{
                    color: 'common.white',
                    bgcolor: 'primary.main',
                    '& .MuiChip-label': {
                      px: 0.75,
                      fontWeight: 700,
                      minWidth: '22px',
                      textAlign: 'center',
                    },
                  }}
                />
              )
            ) : (
              <>
                {selected.length > 1 && (
                  <Chip
                    label={`+${selected.length - 1}`}
                    sx={{
                      color: 'common.white',
                      bgcolor: 'primary.main',
                      ml: 0.5,
                      '& .MuiChip-label': { px: 0.75, fontWeight: 700 },
                    }}
                  />
                )}
              </>
            )}
          </Box>
        );
      }}
      onChange={(e, child) => {
        if (isValidElement(child) && child.key?.includes('search-field')) return;
        const updatedValue = e.target.value as T[];
        if (updatedValue.includes('clear-selection' as T)) {
          props.onChange([]);
        } else if (updatedValue.includes('select-all' as T)) {
          if (isAllSelected) {
            props.onChange([]);
          } else {
            props.onChange(options.map((o) => o.value));
          }
        } else {
          props.onChange(updatedValue.filter(Boolean));
        }
      }}
      MenuProps={{
        ...props.MenuProps,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        sx: joinSx(
          {
            maxHeight: 400,
            '& .MuiMenu-list': {
              pt: props.enableSearch ? 0 : 1,
              minWidth: '15rem',
            },
          },
          props.MenuProps?.sx,
        ),
        // If the user starts typing in the search field, the focus jumps to an item that starts with the letter the users presses
        onKeyDownCapture: (e) => {
          e.stopPropagation();
        },
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      size={(props.size as any) ?? 'medium'}
      fullWidth={props.fullWidth}
      inputProps={{
        sx: joinSx(
          { maxWidth: MAX_INPUT_WIDTH, minWidth: MIN_INPUT_WIDTH },
          props.inputPropsSx,
        ),
      }}
      onOpen={() => {
        setTimeout(() => {
          searchRef.current?.focus();
        }, 0);
        setSearchString('');
      }}
      sx={joinSx(
        {
          borderRadius: '8px',
          borderColor: 'grey.100',
          '& .MuiSelect-select': { py: 0.5 },
        },
        props.sx,
      )}
    >
      {[
        ...(props.enableSearch
          ? [
              <SearchForTextField
                key="search-field"
                inputRef={searchRef}
                disabled={props.disabled}
                value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }}
                onKeyPress={handleSearchKeyDown}
              />,
            ]
          : []),
        ...(props.enableCreate && !exactSearchOption && searchString
          ? [
              <MenuItem
                key={`add-${searchString}`}
                sx={{ p: 2, pb: 1, pl: 4.75, pt: 1.5 }}
                autoFocus={false}
                onClick={(e) => {
                  e.preventDefault();
                  handleAddSearchOption();
                }}
              >
                {searchString}{' '}
                <Typography sx={{ color: 'blue.700', ml: 'auto' }}>
                  <Box component={FontAwesomeIcon} icon={faPlusCircle} sx={{ mr: 0.5 }} />{' '}
                  Enter to Add
                </Typography>
              </MenuItem>,
            ]
          : []),
        ...(groupedOptions.length > 0
          ? [
              props.enableSelectAll ? (
                <MenuItem
                  key="select-all"
                  value="select-all"
                  sx={{
                    pl: 0,
                    '&.Mui-selected': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  disabled={props.disabled}
                >
                  <Checkbox
                    disabled={props.disabled}
                    checked={isAllSelected}
                    size="small"
                    sx={{
                      py: 0.5,
                    }}
                  />
                  <ListItemText primary="Select all" />
                </MenuItem>
              ) : null,
              ...groupedOptions.map((option) => {
                if ((option as GroupHeader<T>).isGroupHeader) {
                  return (
                    <Typography
                      key={`group-${option.label}`}
                      sx={{
                        px: 2,
                        py: 0.75,
                        color: 'grey.500',
                        bgcolor: 'grey.50',
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        textTransform: 'uppercase',
                      }}
                    >
                      {option.label}
                    </Typography>
                  );
                }

                const isDisabled = props.disabled || option.disabled;
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={joinSx(
                      {
                        pl: 0,
                        position: 'relative',
                        '&.Mui-selected': {
                          backgroundColor: 'transparent',
                        },
                      },
                      option.sx,
                      firstTaggedOptionIndex ===
                        options.findIndex((o) => o.value === option.value) &&
                        (firstTaggedOptionIndex > 0 || props.enableSelectAll)
                        ? { borderTop: '1px solid', borderTopColor: 'divider' }
                        : {},
                    )}
                    disabled={isDisabled}
                  >
                    {!option.isLoading ? (
                      <Checkbox
                        disabled={isDisabled}
                        checked={props.value.includes(option.value)}
                        size="small"
                        sx={
                          option.subtitle
                            ? {
                                alignSelf: 'start',
                                pt: 0,
                              }
                            : {
                                py: 0.5,
                              }
                        }
                      />
                    ) : (
                      <Box
                        sx={{
                          alignSelf: 'start',
                          pl: 1.5,
                          pr: 1.25,
                          pt: 0.5,
                        }}
                      >
                        <CircularProgress size="1rem" />
                      </Box>
                    )}

                    <ListItemText primary={option.label} secondary={option.subtitle} />

                    {isDisabled && option.disabledTooltip ? (
                      <Tooltip title={option.disabledTooltip} arrow placement="top">
                        <Box
                          sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            pointerEvents: 'auto',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </MenuItem>
                );
              }),
              props.enableClearSelection && props.value.length > 0 ? (
                <MenuItem
                  key="clear-selection"
                  value="clear-selection"
                  sx={{
                    pl: 0,
                    borderTop: '1px solid',
                    borderTopColor: 'grey.100',
                    '&.Mui-selected': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  disabled={props.disabled}
                >
                  <ListItemText primary="Clear selection" sx={{ ml: 1.5 }} />
                </MenuItem>
              ) : null,
            ].filter(Boolean)
          : []),
        ...(groupedOptions.length === 0 && !props.enableCreate
          ? [
              <Typography key="empty-state" sx={{ p: 2, pb: 1 }}>
                There are no options...
              </Typography>,
            ]
          : groupedOptions.length === 0 && props.enableCreate && !searchString
            ? [
                <Typography key="type-to-add" sx={{ p: 2, pb: 1 }}>
                  Type to create a new option...
                </Typography>,
              ]
            : []),
      ]}
      {props.bottom ? <div>{props.bottom}</div> : null}
    </Select>
  );
};
