import { faLightbulbDollar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Popover, Typography } from '@mui/material';
import smallLogo from 'assets/img/concntric-logo-sm-light.svg';
import { NavBarButton } from 'components/NavBarButton/NavBarButton';
import usePermissions from 'features/Auth/hook/usePermissions';
import { useCreateGenericAnalysis } from 'features/ConceptLab/hooks/analysis';
import { useRecentConceptAnalysis } from 'features/ConceptLab/hooks/useRecentConceptAnalysis';
import { useIsLocationActive } from 'hooks/useIsLocationActive';
import { useTenantFeatureFlags } from 'hooks/useTenantFeatureFlags';
import { useRef, useState } from 'react';
import { PrivateURL } from 'Urls';
import { NavBarPopoverButton } from './NavBarPopoverButton';

export function NavBarConceptLabButton() {
  const {
    tenantFeatureFlags: { concept_lab: isConceptLabEnabled },
  } = useTenantFeatureFlags();
  const { isExactLocationActive } = useIsLocationActive();
  const popoverAnchor = useRef<HTMLDivElement | null>(null);
  const [openedPopover, setOpenedPopover] = useState(false);
  const { hasAnyPerms, Permissions } = usePermissions();
  const { recentConceptAnalysis } = useRecentConceptAnalysis();
  const { createGenericAnalysis } = useCreateGenericAnalysis();

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const id = 'navbar-concept-lab-mouse-over-popover';
  const hasRecentAnalysis = recentConceptAnalysis.length > 0;

  if (
    !isConceptLabEnabled ||
    !hasAnyPerms([
      Permissions.CHANGE_CONCEPT_LAB_ANALYSIS,
      Permissions.ADD_CONCEPT_LAB_ANALYSIS,
    ])
  )
    return null;

  return (
    <>
      <Box
        ref={popoverAnchor}
        aria-owns={id}
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        <NavBarButton
          icon={<FontAwesomeIcon icon={faLightbulbDollar} />}
          sx={{ px: 1 }}
          isActive={
            isExactLocationActive(PrivateURL.CONCEPT_LAB) ||
            isExactLocationActive(PrivateURL.CONCEPT_LAB_ANALYSIS_BY_ID) ||
            isExactLocationActive(PrivateURL.CONCEPT_LAB_CONCEPTS)
          }
          chipLabel="BETA"
        >
          Concept Hub
        </NavBarButton>
      </Box>
      <Popover
        id={id}
        sx={{ pointerEvents: 'none' }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={popoverLeave}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
          sx: {
            pointerEvents: 'auto',
            minWidth: 220,
            overflow: 'auto',
          },
        }}
      >
        {hasRecentAnalysis ? (
          <>
            <Typography
              variant="textDefaultSemiBold"
              sx={{ color: 'grey.500', p: 1.5, display: 'block' }}
            >
              Recent
            </Typography>
            {recentConceptAnalysis.map((recent) => {
              return (
                <NavBarPopoverButton
                  key={recent.id}
                  onClick={popoverLeave}
                  to={PrivateURL.CONCEPT_LAB_ANALYSIS_BY_ID.replace(
                    ':analysisId',
                    String(recent.id),
                  )}
                >
                  <Box
                    component="img"
                    src={smallLogo}
                    alt="Small Concentric logo"
                    sx={{ height: '1.25rem' }}
                  />
                  <Typography variant="textDefaultSemiBold">{recent.name}</Typography>
                </NavBarPopoverButton>
              );
            })}
          </>
        ) : null}
        {recentConceptAnalysis.length > 0 ? <Divider sx={{ my: 1 }} /> : null}
        <NavBarPopoverButton to={PrivateURL.CONCEPT_LAB} onClick={popoverLeave}>
          <Typography variant="textDefaultSemiBold">View All</Typography>
        </NavBarPopoverButton>
        <NavBarPopoverButton
          onClick={() => {
            createGenericAnalysis();
            popoverLeave();
          }}
        >
          <Typography variant="textDefaultSemiBold">Create Analysis</Typography>
        </NavBarPopoverButton>
      </Popover>
    </>
  );
}
